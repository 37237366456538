import Component from '../../libs/component';
import { register } from '../../libs/register';
import { analytics } from '../../libs/analytics';
import { setBlockScroll, removeBlockScroll } from '../base-page';
class Modal extends Component {
    constructor(container) {
        super('widget-modal');

        this.modal = container;
        this.modalId = container.id;
        this.modalBody = this.modal.querySelector(this._el('body',true));
        this.backdrop = container.querySelector(this._el('backdrop',true));
        this.btnClose = container.querySelector(this._el('close',true));
        this.filters = container.querySelector(this._el('filters',true));
        this.modalType = container.dataset.modalType;

        this._addCustomScrollBar = this.addCustomScrollBar.bind(this);
        this._removeCustomScrollBar = this.removeCustomScrollBar.bind(this);

        if (this.modalType == 'two-steps') {
            this.firstStep = container.querySelector('#first-step');
            this.secondStep = container.querySelector('#second-step');
            this.btnBack = container.querySelector(this._el('back',true));
        }

        if (this.modalType == 'press') {
            this.btnDownload = container.querySelector('.btn-download');
        }

        if (this.modalType == 'filters' && this.filters) {
            this.filtersList = this.filters.querySelectorAll('.widget-accordion-select');
            this.resetButton = container.querySelector(this._el('reset-filters',true));
            this.applyBtn = this.modal.querySelector(this._el('apply-filters',true));
        }

        this._modalInit();
    }

    // Init Modal
    _modalInit() {

        this.modal
            .querySelectorAll(`${this._el('cta',true)}, ${this._el('close',true)}, .elem-cta`)
            .forEach((el) => {
                el.addEventListener('click', (ev) => {
                    this.sendAnalytics('click', ev.target.textContent.trim() || ev.target.getAttribute('aria-label'));
                });
            });

        if (this.modalType != 'two-steps') {
            this.btnClose
                .addEventListener('click', (ev) => {
                    ev.preventDefault();
                    this._closeModal();
                });
        }

        if (this.modalType == 'two-steps' || this.modalType == 'press') {
            this.modal
                .querySelector(`${this._el('footer',true)} .btn-close`)
                .addEventListener('click', (ev) => {
                    ev.preventDefault();
                    this._closeModal();
                });
        }

        if (this.modalType == 'two-steps') {

            let modalsObj = JSON.parse(sessionStorage.getItem('modals')) || {};

            if (!modalsObj[this.modalId]) { //if cookie is not present open the modal
                this._openModalOnLoad();
            }

            this.firstStep
                .querySelector(`${this._el('form-button',true)} button`)
                .addEventListener('click', (ev) => {
                    ev.preventDefault();
                    this._nextStep();
                });

            this.btnBack.addEventListener('click', () => this._backStep());

            this.modal
                .querySelector(`${this._el('footer',true)} .btn-close`)
                .addEventListener('click', () => {
                    modalsObj[this.modalId] = true;
                    sessionStorage.setItem("modals", JSON.stringify(modalsObj));
                });
        }

        if (this.modalType == 'press') {

            let cta = document.querySelector("[data-target='" + '#' + this.modalId + "']");
            let ctaId = cta.id;

            if (this.btnDownload)
                this.btnDownload
                    .addEventListener('click', (ev) => {

                        let dataUrl = ev.target.getAttribute('href');
                        this.sendDownloadAnalytics(dataUrl);
                        
                        let pressModal = JSON.parse(sessionStorage.getItem('pressId')) || {};
                        pressModal[ctaId] = true;
                        sessionStorage.setItem("pressId", JSON.stringify(pressModal));

                        this._closeModal();
                    });
        }

        if (this.modalType == 'filters') {

            if (this.filtersList)
                this.filtersList.forEach((acc) => {
                    acc.addEventListener('accordionItemSelected',() => {
                        this._updateFilterApplyBtn();
                    });
                    acc.addEventListener('accordionOpening',() => {
                        this._closeInactiveAccordions();
                    });
                });

            if (this.applyBtn) {
                this.applyBtn.disabled = true;
                this.applyBtn.setAttribute('disabled','');

                this.applyBtn.addEventListener('click',() => {

                    let validatedCount = 0;
                    this.validated = [];

                    this.filtersList.forEach((fl) => {
                        let validatedData = fl.objReference.applyValidatedFilters();
                        validatedCount += validatedData.length;

                        if (validatedData.length)
                            this.validated.push({type: fl.objReference.type, validated: validatedData});
                    });

                    console.log('APPLY',this.validated,validatedCount);
                    
                    this._sendValidatedFiltersEvent(this.validated);
                    this._enableApplyBtn(false);
                    this._closeModal();
                });
            }
            
            if (this.resetButton) {

                this.resetButton.addEventListener('click',() => {
                    this.resetAccordionFilters();
                    this._resetApplyBtn();
                    this._sendFiltersResetEvent();
                    this._closeModal();
                });
            }
        }

        if (this.modalType == 'lang-switcher') {
            const buttonsContainer = this.modal.querySelector(this._el('buttons',true));
            
            if (buttonsContainer.children.length > 3)
                buttonsContainer.classList.add('two-columns');
        }

        document.removeEventListener('bpMobileTablet', this._removeCustomScrollBar);
        document.addEventListener('bpMobileTablet', this._removeCustomScrollBar);
        document.removeEventListener('bpDesktop', this._addCustomScrollBar);
        document.addEventListener('bpDesktop', this._addCustomScrollBar);
        document.removeEventListener('orientLandscape', this._closeModal);
        document.addEventListener('orientLandscape', this._closeModal);
    }

    // Optional cid = CTA ID
    _openModal(cid) {

        if (this.modalType == 'press') {

            let pressModal = JSON.parse(sessionStorage.getItem('pressId')) || {};

            if (pressModal[cid]) { // if a cookie is found download the file
                this.btnDownload.click();
                return; // do not open modal
            }
        }

        setBlockScroll();
        this.modalBody.scrollTo(0, 0);
        this.modal.classList.remove('hide');
        this.modal.classList.add("show");
        this._addClickBackdrop();

        if (this.modalType == 'video')
            this._loadVideo();

        if (window.deviceBreakpoints.bpDesktop.matches === true)
            this.addCustomScrollBar();

        this.sendAnalytics('impression');
    }

    _openModalOnLoad() {

        setBlockScroll();
        this.modal.classList.add("show");
        
        if (window.deviceBreakpoints.bpDesktop.matches === true)
            this.addCustomScrollBar();

        this.sendAnalytics('impression');
    }

    _closeModal() {

        if (this.modal)
            if (this.modal.classList.contains("show")) {

                this.modal.classList.remove('show');
                this.modal.classList.add('hide');
                removeBlockScroll();

                this.removeCustomScrollBar();
                this._removeClickBackdrop();

                if (this.modalType == 'two-steps') {
                    this._backStep();
                }

                if (this.modalType == 'video') {
                    this._pauseVideo();
                    // this._playCarousel();
                }

                if (this.modalType == 'filters') {
                    this._closeInactiveAccordions();
                    this._restoreValidatedFilters();
                    this._updateFilterApplyBtn();
                    this._enableApplyBtn(false);
                }
            }
    }

    _addClickBackdrop() {
        this.backdrop.addEventListener('click', this._clickBackdrop);
    }

    _removeClickBackdrop() {
        this.backdrop.removeEventListener('click', this._clickBackdrop);
    }

    _clickBackdrop({target}) {
        console.log(target);
        target
            .parentElement
            .objReference
            ._closeModal();
    }

    async addCustomScrollBar() {

        const modalBody = this.modal.querySelector(this._el('body',true));
        const PerfectScrollbar = await import('perfect-scrollbar'); 
        this.scrollbar = new PerfectScrollbar.default(modalBody, {
            suppressScrollX: true,
            wheelPropagation: false,
            swipeEasing: true
        });

        this.scrollbar.update();
    }

    removeCustomScrollBar() {

        if (this.scrollbar) {
            this.scrollbar.destroy();
            this.scrollbar = null;
        }
    }

    _nextStep() {
        this.btnBack.classList.add('show');
        this.firstStep.classList.remove('active');
        this.secondStep.classList.add('active');
    }

    _backStep() {
        this.btnBack.classList.remove('show');
        this.firstStep.classList.add('active');
        this.secondStep.classList.remove('active');
    }

    async _loadVideo() {
        
        let iframe = this.modal.querySelector('iframe');
        if(iframe.src !== iframe.dataset.src) {
            iframe.src = iframe.dataset.src;
        }
        

        // this._stopCarousel();

        // try {
        //     const VimeoPlayer = await import('@vimeo/player');
        //     this.videoPlayer = this.videoPlayer || new VimeoPlayer(iframe);
        //     this.videoPlayer.play();
        // } catch(err) {
        //     // load the video iframe
        //     iframe.src = iframe.dataset.src;
        // }
    }

    async _pauseVideo() {

        let iframe = this.modal.querySelector('iframe');

        try {
            const VimeoPlayer = await import('@vimeo/player');
            this.videoPlayer = this.videoPlayer || new VimeoPlayer(iframe);
            this.videoPlayer.pause();
        } catch(err) {
            console.warn('Pause video ERROR',err);
        }
    }

    _stopCarousel() {
        
        let carousels = document.querySelectorAll('.widget-carousel');

        carousels.forEach((el) => {
            el.objReference._pauseSlideshow();
        });
    }

    _playCarousel() {
        
        let carousels = document.querySelectorAll('.widget-carousel');

        carousels.forEach((el) => {
            el.objReference._playSlideshow();
        });
    }

    _closeInactiveAccordions() {
        this.filtersList.forEach((acc) => {
            if (acc.objReference)
                acc.objReference.closeAccordion();
        });
    }

    _restoreValidatedFilters() {
        this.filtersList.forEach((acc) => {
            if (acc.objReference)
                acc.objReference.resetValidatedValues();
        });
    }

    _sendValidatedFiltersEvent(data) {
        let event = new CustomEvent('filtersValidated', { bubbles: true, detail: { accordionValidated: data } });
        this.modal.dispatchEvent(event);
    }

    _sendFiltersResetEvent() {
        let event = new CustomEvent('filtersReset', { bubbles: true });
        this.modal.dispatchEvent(event);
    }

    getValidatedFiltersCount() {

        let validated = 0;

        this.filtersList.forEach((acc) => {
            if (acc.objReference)
                validated += acc.objReference.getValidatedFiltersCount();
        });

        return validated;
    }

    setAccordionFilters(data) {
        //console.log('updating accordions',data)
        this.filtersList.forEach((acc) => {
            if (acc.objReference)
                if (data.type === acc.objReference.type)
                    acc.objReference.setValidatedFilters(data.validated);
        });

        this._enableApplyBtn(false);
    }

    _updateFilterApplyBtn() {

        if (this.applyBtn) {

            let total = 0;
            let validated = 0;

            this.filtersList.forEach((fl) => {
                let acc = fl.objReference;
                total += acc.getSelectedFilters().length;
                validated += acc.getValidatedFiltersCount();
            });

            if (total) {
                this._enableApplyBtn(true,total);
            } else {
                if (!validated) this._resetApplyBtn();
                else this._enableApplyBtn(true,'');
            }
        }
    }

    _enableApplyBtn(enable=true,label) {

        let text = this.applyBtn.querySelector('span');

        if (label !== undefined && label !== null)
            text.textContent = label !== '' ? ` (${label})` : '';
        
        enable ? this.applyBtn.removeAttribute('disabled') : this.applyBtn.setAttribute('disabled','');
        this.applyBtn.disabled = !enable;
    }

    _resetApplyBtn() {

        this._enableApplyBtn(false,'');
    }

    showResetButton(show=true) {
        if (show)
            this.resetButton.classList.remove('hidden');
        else
            this.resetButton.classList.add('hidden');
    }

    resetAccordionFilters() {

        this.filtersList.forEach((fl) => {
            fl.objReference.resetAccordion();
        });
    
        this._resetApplyBtn();
    }

    sendAnalytics(action,cta=null) {

        const title = this.modal.querySelector(this._el('title',true)) || this.modal.querySelector(this._el('header-title',true)),
            content = this.modal.querySelector(this._el('step.active',true)) || this.modal.querySelector(this._el('body',true));
        let ctaList = null;

        //if (!cta)    // Creating an array with cta labels
        ctaList = [...this.modal.querySelectorAll(`${this._el('cta',true)}, .elem-cta`)]
                        .filter((el) => {
                            if (el.offsetParent !== null)
                                return true;    // CTA is visible
                            return false;
                        })
                        .map((el) => el.textContent.trim());

        analytics.sendData({
            'event': 'popup',
            'language': document.documentElement.lang,
            'type': null,
            'popup': {
                'action': action,
                'elements': {
                    'title': title ? title.textContent.trim() : null,
                    'content': content ? content.textContent.replace(/[\n\r]+|[\s]{2,}/g, ' ').trim() : null,
                    'name': null,
                    'cta': {
                        'existing': ctaList || null,
                        'clicked': cta
                    }
                }
            }
        });
    }

    sendDownloadAnalytics(filepath) {

        let fileName = filepath ? filepath.replace(/^.*[\\/]/, '').split('.')[0] : '',
            fileExt = filepath ? filepath.split('.').pop() : '';

        analytics.sendData({
            'event': 'download',
            'language': document.documentElement.lang,
            'download': {
                'action': 'completed',
                'file': {
                    'name': fileName,
                    'category': '',
                    'ext': fileExt
                },
                'isBulk': 'false'
            }
        });
    }
}

register.registerClass('.widget-modal', Modal);