const data = {
  "v": "5.1.13",
  "fr": 25,
  "ip": 0,
  "op": 38,
  "w": 32,
  "h": 32,
  "nm": "0126_letter_#ffffff_32px",
  "ddd": 0,
  "assets": [
    {
      "id": "comp_0",
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          "nm": "Livello 3 contorni",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                520,
                520,
                0
              ],
              "ix": 2
            },
            "a": {
              "a": 0,
              "k": [
                827.5,
                827.5,
                0
              ],
              "ix": 1
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6
            }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          947.5,
                          907.75
                        ],
                        [
                          667.5,
                          907.75
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Tracciato 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "nm": "Traccia 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Trasformazione"
                }
              ],
              "nm": "Gruppo 1",
              "np": 2,
              "cix": 2,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          867.5,
                          1067.75
                        ],
                        [
                          667.5,
                          1067.75
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Tracciato 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "nm": "Traccia 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Trasformazione"
                }
              ],
              "nm": "Gruppo 2",
              "np": 2,
              "cix": 2,
              "ix": 2,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          827.053,
                          747.25
                        ],
                        [
                          667.5,
                          747.25
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Tracciato 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "nm": "Traccia 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Trasformazione"
                }
              ],
              "nm": "Gruppo 3",
              "np": 2,
              "cix": 2,
              "ix": 3,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": {
                      "x": [
                        0.197
                      ],
                      "y": [
                        1
                      ]
                    },
                    "o": {
                      "x": [
                        0.217
                      ],
                      "y": [
                        0
                      ]
                    },
                    "n": [
                      "0p197_1_0p217_0"
                    ],
                    "t": 21.11,
                    "s": [
                      100
                    ],
                    "e": [
                      0
                    ]
                  },
                  {
                    "t": 38
                  }
                ],
                "ix": 1
              },
              "e": {
                "a": 0,
                "k": 100,
                "ix": 2
              },
              "o": {
                "a": 0,
                "k": 0,
                "ix": 3
              },
              "m": 1,
              "ix": 4,
              "nm": "Taglia tracciati 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 75,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          "nm": "Livello 4 contorni",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                743,
                189.5,
                0
              ],
              "ix": 2
            },
            "a": {
              "a": 0,
              "k": [
                1050.5,
                498.5,
                0
              ],
              "ix": 1
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0,
                      0,
                      0.667
                    ],
                    "y": [
                      0.999,
                      0.999,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      1,
                      1,
                      0.333
                    ],
                    "y": [
                      0.001,
                      0.001,
                      0
                    ]
                  },
                  "n": [
                    "0_0p999_1_0p001",
                    "0_0p999_1_0p001",
                    "0p667_1_0p333_0"
                  ],
                  "t": 19.423,
                  "s": [
                    0,
                    0,
                    100
                  ],
                  "e": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "t": 20.2666015625
                }
              ],
              "ix": 6
            }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -17.975,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          17.975
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -97.637,
                          -97.612
                        ],
                        [
                          -97.637,
                          65.065
                        ],
                        [
                          -65.091,
                          97.612
                        ],
                        [
                          97.637,
                          97.612
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Tracciato 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "nm": "Riempimento 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      1045.803,
                      489.888
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Trasformazione"
                }
              ],
              "nm": "Gruppo 1",
              "np": 2,
              "cix": 2,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 75,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          "nm": "linea contorni",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                520,
                520,
                0
              ],
              "ix": 2
            },
            "a": {
              "a": 0,
              "k": [
                827.5,
                827.5,
                0
              ],
              "ix": 1
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6
            }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -18.617
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -18.617,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          18.617
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -18.617,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          18.617
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          18.617,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          319.714,
                          -279.5
                        ],
                        [
                          159.714,
                          -439.5
                        ],
                        [
                          -287.005,
                          -439.5
                        ],
                        [
                          -320.715,
                          -405.791
                        ],
                        [
                          -320.715,
                          405.791
                        ],
                        [
                          -287.005,
                          439.5
                        ],
                        [
                          287.005,
                          439.5
                        ],
                        [
                          320.714,
                          405.791
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Tracciato 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "nm": "Traccia 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      827.786,
                      827
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Trasformazione"
                }
              ],
              "nm": "Gruppo 1",
              "np": 2,
              "cix": 2,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": {
                      "x": [
                        0.667
                      ],
                      "y": [
                        1
                      ]
                    },
                    "o": {
                      "x": [
                        0.333
                      ],
                      "y": [
                        0
                      ]
                    },
                    "n": [
                      "0p667_1_0p333_0"
                    ],
                    "t": 0,
                    "s": [
                      100
                    ],
                    "e": [
                      0
                    ]
                  },
                  {
                    "t": 21.1103515625
                  }
                ],
                "ix": 1
              },
              "e": {
                "a": 0,
                "k": 100,
                "ix": 2
              },
              "o": {
                "a": 0,
                "k": 0,
                "ix": 3
              },
              "m": 1,
              "ix": 2,
              "nm": "Taglia tracciati 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 75,
          "st": 0,
          "bm": 0
        }
      ]
    }
  ],
  "layers": [
    {
      "ddd": 0,
      "ind": 1,
      "ty": 0,
      "nm": "0126_letter_100pt_#ffffff",
      "ln": "ffffff",
      "refId": "comp_0",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            16,
            16,
            0
          ],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [
            520,
            520,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [
            3.07,
            3.07,
            100
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "w": 1040,
      "h": 1040,
      "ip": 0,
      "op": 75,
      "st": 0,
      "bm": 0
    }
  ],
  "markers": []
}
module.exports = data;