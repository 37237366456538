import Component from '../../libs/component';
import { register } from '../../libs/register';


export class Searchbar extends Component {

    constructor(container) {
        super('elem-searchbar');

        this.searchbar = container;
        const searchInput = this.searchbar.querySelector('.elem-searchbar__search');
        const event = new Event('sendSearch');

        searchInput.addEventListener('keyup', (e) => {
            if (searchInput.value.length >= 3) {
                this._chekinput(e, event)
            } else {
                this._inactiveSearch();
            }
        })

        const buttonSearch = this.searchbar.querySelector('.elem-searchbar__icon');
        buttonSearch.addEventListener('click', () => {
            window.dispatchEvent(event);
        })
    }

    _chekinput(e, event) {
        this._activeSearch();

        if (e.keyCode === 13) {
            window.dispatchEvent(event);
        }
    }

    _activeSearch() {
        this.searchbar.classList.add('searchbar__active');
    }

    _inactiveSearch() {
        this.searchbar.classList.remove('searchbar__active');
    }    
        
}

register.registerClass('.elem-searchbar', Searchbar);