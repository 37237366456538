import Component from '../../libs/component';
import { getURLParamsString } from '../../libs/utils';


class LpDob extends Component {

    constructor() {
        super('base-lp-dob');

        this.container = document.querySelector('.lp-dob');
        this.pageUrl;
        this.queryString;
        this.dynamicParametersString;
        this.dynamicParameters;
        this.servletParameters;
        this.jsonResponse;
        this.assetsJson;

        this.dynamicAssetBasePath;
        this.dynamicAssetParametersPath;

        this.imageExtensions = ['.webp', '.png', '.jpg', '.svg', '.gif'];
        this.lottieExtension = '.jsonx';
        this.videoExtension = '.mp4';

        this.dynamicAbiColorMapPath = '/content/dam/nexinew/lp-dob/abi-color/abi-color-map.jsonx';

        this.dataCampaignDamPath = this.container.getAttribute('data-campaign-dam-path');
        this.dataOnboardingUrl = this.container.getAttribute('data-onboarding-url');

        this._init();
    }

    _init() {
        this._initPageUrl();
        this._initDynamicParameters();
        this._initOnboardingUrl();
        this._setTopBarOnboardingUrl();
        this.assetsJson = this._getAssetsJson();
    }

    _initPageUrl() {
        let splitPageUrl = window.location.href.split('?');
        this.pageUrl = splitPageUrl[0].replace('.html', '');
        this.queryString = getURLParamsString();
        this.dynamicParametersString = this.queryString.replaceAll('%26', 'and').toLowerCase();
    }

    _initDynamicParameters() {
        const allowedQueryStringParameters = ['idiniziativa', 'circuito', 'nomeprodottonexi', 'abi', 'promo'];
        let servletParametersString = [];
        let searchParams = new URLSearchParams(this.dynamicParametersString);
        let searchParamsKeys = [...searchParams.keys()];

         for(let paramName of searchParamsKeys) {
            if (!allowedQueryStringParameters.includes(paramName)) {
                searchParams.delete(paramName);
                continue;
            }

            servletParametersString.push('.', paramName, '-', searchParams.get(paramName));
        }

        this.dynamicParameters = searchParams;
        this.servletParameters = servletParametersString.join('');
    }

    _initOnboardingUrl() {
        if (!this.queryString || !this.dataOnboardingUrl) {
            return;
        }

        const allowedQueryStringParameters = [
            'idiniziativa',
            'circuito',
            'nomeprodottonexi',
            'abi',
            'abibancapartner',
            'affinity',
            'cpp',
            'idunivocoofferta',
            'procedure_id',
            'offer_id'
        ];

        let onboardingParametersString = ['?'];
        let searchParams = this.queryString.replace('?', '').split('&');

         for(let param of searchParams) {
            if (allowedQueryStringParameters.includes(param.split('=')[0])) {
                onboardingParametersString.push(param, '&');
            }
        }

        onboardingParametersString.pop();
        onboardingParametersString = onboardingParametersString.join('');
        this.dataOnboardingUrl += onboardingParametersString;
    }

    _setTopBarOnboardingUrl() {
        if (!this.dataOnboardingUrl) {
            return;
        }

        let headerCta = this.container.querySelector('.base-header-lp-dob__cta');
        if (headerCta.hasAttribute("href")) {
            headerCta.setAttribute('href', this.dataOnboardingUrl);
        }

        let stickyBarCtaList = this.container.querySelectorAll('.base-sticky-bar-dob__cta');
        for (let cta of stickyBarCtaList) {
            if (cta.hasAttribute("href")) {
                cta.setAttribute('href', this.dataOnboardingUrl);
            }
        }
    }

    _setHeaderBackgroundColor() {
        let headerBar = this.container.querySelector('.base-header-lp-dob__bar');

        if (headerBar) {
            this._setBackgroundColor(headerBar, 'secondary');
        }

        let stickyBar = this.container.querySelector('.base-sticky-bar-dob__nav-top');

        if (stickyBar) {
            this._setBackgroundColor(stickyBar, 'secondary');
        }
    }

    async _getJsonResponse() {
        if (this.jsonResponse != null) {
            return this.jsonResponse;
        }

        let resPath = this.pageUrl + '/jcr:content.lpdob' + this.servletParameters + '.json';

        try {
            this.jsonResponse = await fetch(resPath).then(res => res.json());
            return this.jsonResponse;
        } catch (error) {
            console.warn('ERROR on Landing Onboarding servlet', error);
        }
    }

    async _getAssetsJson() {
        if (this.assetsJson != null) {
            return this.assetsJson;
        }

        let resPath = window.location.origin + '/' + this.dataCampaignDamPath + '.dynamicAssets.json';

        try {
            this.assetsJson = await fetch(resPath).then(res => res.json());
            return this.assetsJson;
        } catch (error) {
            console.warn('ERROR on LP DOB Dynamic Assets servlet:', error);
        }
    }

    _getPageUrl() {
        return this.pageUrl;
    }

    _getQueryString() {
        return this.queryString;
    }

     async _setDynamicAssets(componentContainer, componentFolder, componentName, allowedParameters, removeOnFail) {
        if (!componentContainer) {
            console.warn(componentName, ': missing image component container');
            return;
        }

        if (!allowedParameters || !allowedParameters.length) {
            console.warn(componentName, ': missing allowed dynamic image parameters');
            return;
        }

        this._setDynamicAssetBasePath();
        let dynamicAssetParametersPath = this._getDynamicAssetParametersPath(allowedParameters);

        if (this.dynamicAssetBasePath == null || dynamicAssetParametersPath == null) {
            console.warn(componentName, ': missing dynamic asset paths');
            return;
        }

        let assetsJson = await this._getAssetsJson();

        if (!assetsJson) {
            console.warn('Assets folder empty or non existing');
            return;
        }

        let assetPath = [];
        assetPath.push(
            this.dynamicAssetBasePath, '/', componentFolder, '/', componentName, dynamicAssetParametersPath
        );
        assetPath = assetPath.join('');

        let extensionRegex = new RegExp(componentName + dynamicAssetParametersPath + '.*$');

        if (!assetsJson[componentFolder]) {
            console.warn('No assets file for component: ' + componentName + ' found');
            return;
        }
        let matchingFile = assetsJson[componentFolder].filter(s => s.match(extensionRegex))[0];

        if (!matchingFile) {
            return;
        }

        let fileExtension = matchingFile.split(/(?=\.)/)[1];

        if (this.imageExtensions.includes(fileExtension)) {
            this._setDynamicImages(
                assetsJson[componentFolder],
                componentContainer,
                assetPath,
                fileExtension,
                removeOnFail
            );
        }

        if (this.lottieExtension === fileExtension) {
            this._setDynamicLottieAnimation(
                assetsJson[componentFolder],
                componentContainer,
                assetPath,
                removeOnFail);
        }

        if (this.videoExtension === fileExtension) {
            console.log("mp4");
        }
    }

    _setDynamicAssetBasePath() {
        if (this.dynamicAssetBasePath == null) {
            if (this.dataCampaignDamPath == null) {
                console.warn('LP DOB: missing path to campaign folder');
                return;
            }

            this.dynamicAssetBasePath = this.dataCampaignDamPath + '/assets';
        }
    }

    _getDynamicAssetParametersPath(allowedParameters) {
        if (!allowedParameters.every(parameter => this.dynamicParameters.has(parameter))) {
            console.warn('LP DOB: missing query string parameters');
            return;
        }

        let dynamicAssetSuffix = [];
        for (let param of allowedParameters) {
            dynamicAssetSuffix.push('-', this.dynamicParameters.get(param));
        }
        return dynamicAssetSuffix.join('');
    }

    _setDynamicImages(componentAssets, componentContainer, imagePath, imageExtension, removeOnFail) {
        let pictureMobile = componentContainer.querySelector("[media*='(max-width: 767px)']");
        let pictureTablet = componentContainer.querySelector("[media*='(max-width: 1023px)']");
        let pictureDesktop = componentContainer.querySelector("[media*='(max-width: 900px) and (orientation: landscape)']");

        let desktopImagePath = imagePath + '-d' + imageExtension;

        let existsDefaultImage = this._setDefaultDynamicImage(
            componentAssets, pictureDesktop, desktopImagePath, componentContainer, removeOnFail
        );

        if (!existsDefaultImage) {
            return;
        }

        let imageComponent = componentContainer.querySelector('.media-picture__img');

        if (!imageComponent) {
            console.warn('Missing image component');
            return;
        }

        this._setImageSource(imageComponent, desktopImagePath);

        this._setDynamicImage(componentAssets, pictureMobile, imagePath + '-m' + imageExtension, desktopImagePath);
        this._setDynamicImage(componentAssets, pictureTablet, imagePath + '-t' + imageExtension, desktopImagePath);

        let pictureSources = componentContainer.getElementsByTagName('source');

        for (let i = 0; i < pictureSources.length; i++) {
            if (pictureSources[i].media.length <= 0) {
                this._setPictureSource(pictureSources[i], desktopImagePath);
            }
        }
    }

    _setDefaultDynamicImage(componentAssets, pictureComponent, imagePath, componentContainer, removeOnFail) {
        let imageName = imagePath.split('/').pop();
        if (componentAssets.includes(imageName)) {
            this._setPictureSource(pictureComponent, imagePath);
            return true;
        }
        else {
            if (removeOnFail) {
                componentContainer.remove();
            }
            return false;
        }
}

    _setDynamicImage(componentAssets, pictureComponent, imagePath, fallbackPath) {
        let imageName = imagePath.split('/').pop();
        if (componentAssets.includes(imageName)) {
            this._setPictureSource(pictureComponent, imagePath);
        }
        else {
            this._setPictureSource(pictureComponent, fallbackPath);
        }
    }

    _setPictureSource(pictureComponent, newSource) {
        if (pictureComponent.hasAttribute('srcset')) {
            pictureComponent.setAttribute('srcset', newSource);
        }

        if (pictureComponent.hasAttribute('data-srcset')) {
            pictureComponent.setAttribute('data-srcset', newSource);
        }
    }

    _setImageSource(imageComponent, newSource) {
        if (imageComponent.hasAttribute('src')) {
            imageComponent.setAttribute('src', newSource);
        }

        if (imageComponent.hasAttribute('data-src')) {
            imageComponent.setAttribute('data-src', newSource);
        }
    }

    async _setDynamicLottieAnimation(componentAssets, componentContainer, animationPath, removeOnFail) {

        animationPath = animationPath + this._getDeviceBreakpointString() + '.jsonx'
        let animationName = animationPath.split('/').pop();

        if (!componentAssets.includes(animationName)) {
            if (removeOnFail) {
                componentContainer.remove();
            }
            console.warn('No Lottie animation with name: ' + animationName + ' found');
            return;
        }

        componentContainer.innerHTML = '';
        componentContainer.classList.add('dynamic-animation-container');

        try {
            fetch(animationPath)
                .then((res) => res.json())
                .then(async (data) => {
                    const params = {
                        container: componentContainer,
                        renderer: 'svg',
                        loop: true,
                        autoplay: true,
                        animationData: data
                    };
                    const Lottie = await import('lottie-web/build/player/lottie_light');
                    this.lottie = Lottie.default.loadAnimation(params);
            });
        } catch (error) {
            console.warn('Dynamic Landing loading network ERROR', error);
        }
    }

    _setOnboardingUrl(componentLink) {
        componentLink.setAttribute('href', this.dataOnboardingUrl);
    }

    _setBackgroundColor(componentContainer, colorKey = 'primary') {
        try {
            fetch(this.dynamicAbiColorMapPath)
                .then((res) => res.json())
                .then((data) => {
                    let abi = this.dynamicParameters.get('abi');

                    if (!abi) {
                        return;
                    }

                    let backgroundColor = data[abi][colorKey];
                    if (data && backgroundColor) {
                        componentContainer.setAttribute(
                            'style', 'background-color: ' + backgroundColor + ';'
                        );
                    }
                else {
                    console.warn('ABI color map not found');
                }
            });
        } catch (error) {
            console.warn('Dynamic Landing loading network ERROR', error);
        }
    }

    _getDeviceBreakpointString() {
        let deviceBreakpoint = '-d';

        if (window.deviceBreakpoints.bpTablet.matches === true) {
            deviceBreakpoint = '-t';
        }

        if (window.deviceBreakpoints.bpMobile.matches === true) {
            deviceBreakpoint = '-m';
        }

        return deviceBreakpoint;
    }
}

const lpDob = new LpDob();

export const getDynamicPageUrl = () => {
    return lpDob._getPageUrl();
};

export const getDynamicPageQueryString = () => {
    return lpDob._getQueryString();
};

export const getDynamicPageJsonResponse =(() => {
    let requestPending = null;
    const onFulfill = data => { requestPending = null; return data};
    const onReject = err => { requestPending = null; throw err};
    let jsonResponse = () =>  requestPending || (requestPending = lpDob._getJsonResponse().then(onFulfill, onReject));
    return jsonResponse;
})();

export const setDynamicComponentAssets = (
        componentContainer, componentFolder, componentName, allowedParameters, removeOnFail = false
    ) => {
    lpDob._setDynamicAssets(componentContainer, componentFolder, componentName, allowedParameters, removeOnFail);
};

export const setDynamicOnboardingUrl = (componentLink) => {
    lpDob._setOnboardingUrl(componentLink);
};