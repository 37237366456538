import Component from '../../libs/component';
import { register } from '../../libs/register';


class FilterTable extends Component {

    constructor(container) {
        super('elem-custom-select-filter-table');

        this.container = container;
        this.expanded = false;
        this.box = container.querySelector(`${this._el('box', true)}`);
        this.optionContainer = container.querySelector(`${this._el('select-options', true)}`);
        this.optionsInputs = this.optionContainer.querySelectorAll('input');
        this._init();
    }

    _init() {
        this.box.addEventListener('click', () => {
            this._toggleOptions()
        });

        document.body.addEventListener('click', event => {
            if (!this.container.contains(event.target)) {
                this.box.classList.remove('selected');
                this.optionContainer.classList.remove('show');
            }
        });

        this.optionsInputs.forEach(input => {
            input.addEventListener('change', () => {
                this._addFilters();
            });
        });

        this._addCustomScrollbar();
    }

    _addFilters() {
        let filters = this.optionContainer.querySelectorAll('input:checked');
        let filtersList = [];
        filters.forEach(filter => {
            filtersList.push(filter.value);
        });

        if (filtersList.length > 0) {
            this.container.dataset.filters = filtersList.toString();
        } else {
            this.container.dataset.filters = '';
        }
    }

    _toggleOptions() {
        this.box.classList.toggle('selected');
        this.optionContainer.classList.toggle('show');
    }


    async _addCustomScrollbar() {

        const scrollbar = this.container.querySelector('.elem-custom-select-filter-table__select-options-scroll-wrapper');

        if (scrollbar) {
            const PerfectScrollbar = await import('perfect-scrollbar');
            this.scrollbar = new PerfectScrollbar.default(scrollbar, {
                //scrollYMarginOffset: '11',
                suppressScrollX: true,
                wheelPropagation: false,
                swipeEasing: true
            });

            this.scrollbar.update();

            const scrollbarRail = scrollbar.querySelector('.ps__rail-y');
            scrollbarRail.addEventListener('click', (ev) => {
                ev.stopImmediatePropagation();  // Disable select closing on scrollbar click
            });
        }
    }
}

register.registerClass('.elem-custom-select-filter-table', FilterTable);