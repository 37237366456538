const data = {
  "v": "5.1.13",
  "fr": 25,
  "ip": 0,
  "op": 38,
  "w": 32,
  "h": 32,
  "nm": "1226_play_9596AF_32px",
  "ddd": 0,
  "assets": [
    {
      "id": "comp_0",
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          "nm": "Livello 3 contorni 2",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                520,
                520,
                0
              ],
              "ix": 2
            },
            "a": {
              "a": 0,
              "k": [
                827.5,
                827.5,
                0
              ],
              "ix": 1
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0,
                      0,
                      0.667
                    ],
                    "y": [
                      0.936,
                      0.936,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      1,
                      1,
                      0.333
                    ],
                    "y": [
                      0,
                      0,
                      0
                    ]
                  },
                  "n": [
                    "0_0p936_1_0",
                    "0_0p936_1_0",
                    "0p667_1_0p333_0"
                  ],
                  "t": 15,
                  "s": [
                    0,
                    0,
                    100
                  ],
                  "e": [
                    110,
                    110,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.506,
                      0.506,
                      0.833
                    ],
                    "y": [
                      1.449,
                      1.449,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      1,
                      1,
                      0.167
                    ],
                    "y": [
                      0,
                      0,
                      0
                    ]
                  },
                  "n": [
                    "0p506_1p449_1_0",
                    "0p506_1p449_1_0",
                    "0p833_1_0p167_0"
                  ],
                  "t": 25,
                  "s": [
                    110,
                    110,
                    100
                  ],
                  "e": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "t": 38
                }
              ],
              "ix": 6
            }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -100,
                          -120
                        ],
                        [
                          100,
                          0
                        ],
                        [
                          -100,
                          120
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Tracciato 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.584313750267,
                      0.588235318661,
                      0.686274528503,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "nm": "Traccia 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.58431372549,
                      0.588235294118,
                      0.686274509804,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "nm": "Riempimento 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      847.5,
                      827.5
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Trasformazione"
                }
              ],
              "nm": "Gruppo 1",
              "np": 3,
              "cix": 2,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 75,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          "nm": "linea contorni 2",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": -90,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                520,
                520,
                0
              ],
              "ix": 2
            },
            "a": {
              "a": 0,
              "k": [
                827.5,
                827.5,
                0
              ],
              "ix": 1
            },
            "s": {
              "a": 0,
              "k": [
                -100,
                100,
                100
              ],
              "ix": 6
            }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          243.005,
                          0
                        ],
                        [
                          0,
                          243.005
                        ],
                        [
                          -243.005,
                          0
                        ],
                        [
                          0,
                          -243.005
                        ]
                      ],
                      "o": [
                        [
                          -243.005,
                          0
                        ],
                        [
                          0,
                          -243.005
                        ],
                        [
                          243.005,
                          0
                        ],
                        [
                          0,
                          243.005
                        ]
                      ],
                      "v": [
                        [
                          0,
                          440
                        ],
                        [
                          -440,
                          0
                        ],
                        [
                          0,
                          -440
                        ],
                        [
                          440,
                          0
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Tracciato 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.584313750267,
                      0.588235318661,
                      0.686274528503,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "nm": "Traccia 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      827.5,
                      827.5
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Trasformazione"
                }
              ],
              "nm": "Gruppo 1",
              "np": 2,
              "cix": 2,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 0,
                "k": 100,
                "ix": 1
              },
              "e": {
                "a": 1,
                "k": [
                  {
                    "i": {
                      "x": [
                        1
                      ],
                      "y": [
                        1
                      ]
                    },
                    "o": {
                      "x": [
                        0
                      ],
                      "y": [
                        0
                      ]
                    },
                    "n": [
                      "1_1_0_0"
                    ],
                    "t": 0,
                    "s": [
                      100
                    ],
                    "e": [
                      0
                    ]
                  },
                  {
                    "t": 15
                  }
                ],
                "ix": 2
              },
              "o": {
                "a": 0,
                "k": 0,
                "ix": 3
              },
              "m": 1,
              "ix": 2,
              "nm": "Taglia tracciati 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 75,
          "st": 0,
          "bm": 0
        }
      ]
    }
  ],
  "layers": [
    {
      "ddd": 0,
      "ind": 1,
      "ty": 0,
      "nm": "1226_play_100pt_9596AF",
      "refId": "comp_0",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            16,
            16,
            0
          ],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [
            520,
            520,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [
            3.07,
            3.07,
            100
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "w": 1040,
      "h": 1040,
      "ip": 0,
      "op": 75,
      "st": 0,
      "bm": 0
    }
  ],
  "markers": []
}
module.exports = data;