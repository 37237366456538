import Component from '../../libs/component';
import { register } from '../../libs/register';
import { analytics } from '../../libs/analytics';
import { getPrevSibling } from '../../libs/utils';

class AccordionFaq extends Component {

    constructor(container) {
        super('elem-accordion-faq');

        this.accordion = container;
        this.accordionContainer = this.accordion.querySelector('.elem-accordion-faq__faqs-container');
        this.data = null;
        this.loadItem = [];
        this.searchArray = [];
        this.itemToLoadIndex = 0;  // index for assign id to accordions for showMore function
        this.initialTemplate = document.importNode(document.getElementById('elem-accordion__items'), true);
        this.searchbar = document.querySelector('.elem-searchbar');
        this.searchbarInput = document.querySelector('.elem-searchbar__search');
        this.accordionResult = this.accordion.querySelector('.elem-accordion-faq__result-text');
        this.labelFrequency = this.accordion.querySelector('.elem-accordion-faq__label-frequent-ask')

        if (this.accordion.classList.contains(('selective-mode'))) {
            this._initSelectiveMode();
        }

        this.fiveAccordionFaq = this.accordion.querySelector('.elem-accordion-faq__buttons-container');

        if (this.fiveAccordionFaq) {
            this._initFaqButtons();
        }

        this._getFaq();

        window.addEventListener('sendSearch', () => {
            let valueSearch = this.searchbarInput.value;
            this._findKey(this.data, valueSearch);
        }, false);

        const deleteSearch = this.accordion.querySelector('.elem-accordion-faq__result-delete');
        deleteSearch.addEventListener('click', (e) => {
            e.preventDefault();
            this._resetSearch();
        });

        this.accordion.addEventListener('click', ({target}) => {
            if (target.tagName != 'LABEL') return;
            const checkbox = getPrevSibling(target, 'input[type=checkbox]:not(:checked)');
            if (checkbox)
                this.sendOpenAnalytics(target);
        });
    }

    _initSelectiveMode() {
        this.accordion.addEventListener('click', ({ target: label }) => {
            if (label.tagName != 'LABEL') return;

            const activeCheckboxes = this.accordion.querySelectorAll('input[type=checkbox]:checked');

            activeCheckboxes.forEach((cb) => {
                setTimeout(() => { cb.checked = false }, 0);
            });
        });
    }

    _initFaqButtons() {
        // load 5 accordion for click. loadItem contains loaded accordion
        const showMoreButton = this.accordion.querySelector('.elem-accordion-faq__label-more');

        showMoreButton.addEventListener('click', () => {
            this._showMore();
        });

        // remove all items and load first 5. add to allAccordion loaded items
        const showLessButton = this.accordion.querySelector('.elem-accordion-faq__label-less');

        showLessButton.addEventListener('click', () => {
            this._showLess();
        });
    }

    _getFaq() {
        let urlFaq = this.accordion.getAttribute('data-url');

        fetch(urlFaq)
            .then(this._handleErrors)
            .then(response => response.json())
            .then(data => { this.data = data.data; this._loadFaq(this.data) })
            .catch(err => console.log('Failed request', err));
    }

    _handleErrors(response) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
    }

    _loadFaq(allAccordion) {
        this.accordion.classList.remove('less-view');
        this.loadItem = [];

        if (this.fiveAccordionFaq && allAccordion.length > 5) {
            this.accordion.classList.add('more-view');
            for (let i = 0; i < 5; i++) {
                this.accordionContainer.append(this._renderAccordion(i, allAccordion[i]));
                this.loadItem.push(allAccordion[i]);
            }
        } else {
            allAccordion.forEach((singleItem, i) => {
                this.accordionContainer.append(this._renderAccordion(i, singleItem));
            });
        }
    }

    _renderAccordion(id, { question, answer, category }) {
        const template = document.getElementById('elem-accordion__items');
        const dynamicTemplate = document.importNode(template.content, true);
        dynamicTemplate.querySelector('.elem-accordion-faq__item').setAttribute('data-categories', `${category}`);
        dynamicTemplate.querySelector('.elem-accordion-faq__header-checkbox').setAttribute('id', `${id}`);
        dynamicTemplate.querySelector('.elem-accordion-faq__header').setAttribute('for', `${id}`);
        dynamicTemplate.querySelector('.elem-accordion-faq__title-text').innerHTML = `${question}`;
        dynamicTemplate.querySelector('.elem-accordion-faq__text').innerHTML = `${answer}`;

        return dynamicTemplate;
    }

    _showMore() {
        let accordionArray = null;
        // accordionArray contains all items
        this.accordionResult.classList.contains('result-text--show') ? accordionArray = this.searchArray : accordionArray = this.data;

        // itemToLoad contains all items except loaded ones
        const itemToLoad = accordionArray.slice(this.loadItem.length);

        this.itemToLoadIndex += 5;
        let index = 5;

        if (itemToLoad.length < 5) {
            index = itemToLoad.length;
        }

        // load 5 more items and pushe them into loadItem
        for (let i = 0; i < index; i++) {
            this.accordionContainer.append(this._renderAccordion(this.itemToLoadIndex + i, itemToLoad[i]));
            this.loadItem.push(itemToLoad[i]);
        }

        // when all items are loaded, show 'show less' button and hide 'show more' button
        if (this.loadItem.length === accordionArray.length) {
            this.accordion.classList.remove('more-view');
            this.accordion.classList.add('less-view');
        }
    }

    _showLess() {
        let accordionArray = null;
        this.accordionResult.classList.contains('result-text--show') ? accordionArray = this.searchArray : accordionArray = this.data;
        this.accordion.classList.add('more-view');
        this.accordion.classList.remove('less-view');

        // remove all items from loadItem and from DOM
        this.loadItem = [];
        this.itemToLoadIndex = 0;
        this.accordionContainer.innerHTML = '';
        this.accordionContainer.append(this.initialTemplate);

        let index = 5;
        if (accordionArray.length < 5) {
            index = accordionArray.length;
        }

        //load first 5 accordions and push them into loadItem
        for (let i = 0; i < index; i++) {
            this.accordionContainer.append(this._renderAccordion(i, accordionArray[i]));
            this.loadItem.push(accordionArray[i]);
        }

        const valueForScroll = 40; //random number for graphic reasons
        const offsetTop = this.accordion.getBoundingClientRect().top + (window.scrollY || window.pageYOffset) - valueForScroll;
        this._scrollToTop(0, offsetTop);
    }

    _findKey(allData, valueSearch) {
        this.accordion.classList.remove('more-view');
        this.accordionContainer.innerHTML = '';
        this.accordionContainer.append(this.initialTemplate);
        let countElementKey = 0;
        let arrayCorrespondence = [];
        const arrayWordsCheck = [];

        allData.map((itemData) => {
            const question = itemData.question;
            const answer = itemData.answer;
            const arrayWords = valueSearch.split(' ');
            let countItemQuestion = 0;
            let countItemAnswer = 0;
            const arrayExclude = ['di', 'il', 'si', 'in', 'ad', 'ho', 'la', 'al', 'per', 'non', 'con'];

            arrayWords.map((singleWord) => {
                // loop through the array that contains all searched words except excluded words, then push them into arrayWordsCheck
                if (arrayExclude.indexOf(singleWord.toString().toLowerCase()) == -1) {
                    if (singleWord.trim()) {
                        arrayWordsCheck.push(singleWord);
                    }
                }
            });

            if (arrayWordsCheck.length > 0) {
                arrayWordsCheck.map((itemWord) => {
                    const checkQuestionSingle = question.toString().toLowerCase().indexOf(itemWord.toString().toLowerCase());
                    const answerQuestionSingle = answer.toString().toLowerCase().indexOf(itemWord.toString().toLowerCase());

                    // if question contains searched string, increment countItemquestion
                    if (checkQuestionSingle != -1) {
                        countItemQuestion++;
                    }

                    // if question contains searched string, increment countItemAnswer
                    if (answerQuestionSingle != -1) {
                        countItemAnswer++;
                    }
                });

                // if countItemQuestion or countItemAnswer is equal to arrayWordsCheck.length, increment countElementKey and push the item in arrayCorrespondence
                if (countItemQuestion == arrayWordsCheck.length || countItemAnswer == arrayWordsCheck.length) {
                    countElementKey++;
                    arrayCorrespondence.push(itemData);
                }
            }
        });

        const number = this.accordionResult.querySelector('.elem-accordion-faq__result-label')
        const value = this.accordionResult.querySelector('.elem-accordion-faq__result-label-text');
        number.innerHTML = countElementKey;
        value.innerHTML = valueSearch;
        this.accordionResult.classList.add('result-text--show');

        if (arrayCorrespondence.length == 0) {
            this.accordionResult.classList.add('result-text--no-result')
        } else {
            this.accordionResult.classList.remove('result-text--no-result')
        }

        if (this.labelFrequency) {
            this.labelFrequency.classList.add('frequent-ask--hidden');
        }

        this._loadFaq(arrayCorrespondence);
        this.searchArray = arrayCorrespondence;
    }

    _resetSearch() {
        this.searchbarInput.value = '';
        this.searchbar.classList.remove('searchbar__active');
        this.accordionResult.classList.remove('result-text--show');
        this.accordionResult.classList.remove('result-text--no-result');
        this.accordionContainer.innerHTML = '';
        this.accordionContainer.append(this.initialTemplate);
        this.accordion.classList.remove('less-view');

        if (this.labelFrequency) {
            this.labelFrequency.classList.remove('frequent-ask--hidden');
        }

        this._getFaq();
        this._scrollToTop(0, 0);
    }

    _scrollToTop(left, top) {
        let scrollOptions = {
            left: left,
            top: top,
            behavior: 'smooth'
        }

        window.scrollTo(scrollOptions);
    }

    sendOpenAnalytics(acc) {

        analytics.sendData({
            event: 'accordion',
            accordion: {
                action: 'open',
                title: acc.textContent.trim()
            }
        });
    }
}


register.registerClass('.elem-accordion-faq', AccordionFaq);