const data = {
  "v": "5.1.13",
  "fr": 25,
  "ip": 0,
  "op": 38,
  "w": 32,
  "h": 32,
  "nm": "0239_microphone_#9596AF_32px",
  "ddd": 0,
  "assets": [
    {
      "id": "comp_0",
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          "nm": "Livello 3 contorni 4",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                520,
                660,
                0
              ],
              "ix": 2
            },
            "a": {
              "a": 0,
              "k": [
                570,
                390,
                0
              ],
              "ix": 1
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6
            }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          570,
                          570
                        ],
                        [
                          570,
                          690
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Tracciato 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.584313750267,
                      0.588235318661,
                      0.686274528503,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "nm": "Traccia 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Trasformazione"
                }
              ],
              "nm": "Gruppo 2",
              "np": 2,
              "cix": 2,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          730,
                          690
                        ],
                        [
                          410,
                          690
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Tracciato 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.584313750267,
                      0.588235318661,
                      0.686274528503,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "nm": "Traccia 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Trasformazione"
                }
              ],
              "nm": "Gruppo 1",
              "np": 2,
              "cix": 2,
              "ix": 2,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": {
                      "x": [
                        0.667
                      ],
                      "y": [
                        1
                      ]
                    },
                    "o": {
                      "x": [
                        0.333
                      ],
                      "y": [
                        0
                      ]
                    },
                    "n": [
                      "0p667_1_0p333_0"
                    ],
                    "t": 0,
                    "s": [
                      100
                    ],
                    "e": [
                      0
                    ]
                  },
                  {
                    "t": 14.23046875
                  }
                ],
                "ix": 1
              },
              "e": {
                "a": 0,
                "k": 100,
                "ix": 2
              },
              "o": {
                "a": 0,
                "k": 0,
                "ix": 3
              },
              "m": 2,
              "ix": 3,
              "nm": "Taglia tracciati 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 75,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          "nm": "Livello 3 contorni 3",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                520,
                660,
                0
              ],
              "ix": 2
            },
            "a": {
              "a": 0,
              "k": [
                570,
                390,
                0
              ],
              "ix": 1
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6
            }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          163.184,
                          0
                        ],
                        [
                          19.684,
                          157.852
                        ]
                      ],
                      "o": [
                        [
                          -19.685,
                          157.852
                        ],
                        [
                          -163.184,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          320,
                          -140
                        ],
                        [
                          0,
                          140
                        ],
                        [
                          -320,
                          -140
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Tracciato 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.584313750267,
                      0.588235318661,
                      0.686274528503,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "nm": "Traccia 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      570,
                      390
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Trasformazione"
                }
              ],
              "nm": "Gruppo 3",
              "np": 2,
              "cix": 2,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": {
                      "x": [
                        0.667
                      ],
                      "y": [
                        1
                      ]
                    },
                    "o": {
                      "x": [
                        0.333
                      ],
                      "y": [
                        0
                      ]
                    },
                    "n": [
                      "0p667_1_0p333_0"
                    ],
                    "t": 6,
                    "s": [
                      100
                    ],
                    "e": [
                      0
                    ]
                  },
                  {
                    "t": 18.78515625
                  }
                ],
                "ix": 1
              },
              "e": {
                "a": 0,
                "k": 100,
                "ix": 2
              },
              "o": {
                "a": 0,
                "k": 0,
                "ix": 3
              },
              "m": 2,
              "ix": 2,
              "nm": "Taglia tracciati 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 6,
          "op": 80,
          "st": 5,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          "nm": "Livello 4 contorni 2",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": 0.667,
                    "y": 1
                  },
                  "o": {
                    "x": 0.333,
                    "y": 0
                  },
                  "n": "0p667_1_0p333_0",
                  "t": 21.517,
                  "s": [
                    520,
                    340,
                    0
                  ],
                  "e": [
                    520,
                    323.322,
                    0
                  ],
                  "to": [
                    0,
                    -2.77971458435059,
                    0
                  ],
                  "ti": [
                    0,
                    0,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.667,
                    "y": 1
                  },
                  "o": {
                    "x": 0.333,
                    "y": 0
                  },
                  "n": "0p667_1_0p333_0",
                  "t": 29.714,
                  "s": [
                    520,
                    323.322,
                    0
                  ],
                  "e": [
                    520,
                    340,
                    0
                  ],
                  "to": [
                    0,
                    0,
                    0
                  ],
                  "ti": [
                    0,
                    -2.77971458435059,
                    0
                  ]
                },
                {
                  "t": 37
                }
              ],
              "ix": 2
            },
            "a": {
              "a": 0,
              "k": [
                410,
                510,
                0
              ],
              "ix": 1
            },
            "s": {
              "a": 0,
              "k": [
                -100,
                100,
                100
              ],
              "ix": 6
            }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          88.365
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          88.365,
                          0
                        ],
                        [
                          0,
                          -88.365
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -88.365,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -88.365
                        ],
                        [
                          -88.365,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          88.365
                        ],
                        [
                          88.365,
                          0
                        ]
                      ],
                      "v": [
                        [
                          160,
                          100
                        ],
                        [
                          160,
                          -100
                        ],
                        [
                          0,
                          -260
                        ],
                        [
                          -160,
                          -100
                        ],
                        [
                          -160,
                          100
                        ],
                        [
                          0,
                          260
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Tracciato 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.584313750267,
                      0.588235318661,
                      0.686274528503,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "nm": "Traccia 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      410,
                      510
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Trasformazione"
                }
              ],
              "nm": "Gruppo 1",
              "np": 2,
              "cix": 2,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": {
                      "x": [
                        0.667
                      ],
                      "y": [
                        1
                      ]
                    },
                    "o": {
                      "x": [
                        0.333
                      ],
                      "y": [
                        0
                      ]
                    },
                    "n": [
                      "0p667_1_0p333_0"
                    ],
                    "t": 14.23,
                    "s": [
                      100
                    ],
                    "e": [
                      0
                    ]
                  },
                  {
                    "t": 26.0703125
                  }
                ],
                "ix": 1
              },
              "e": {
                "a": 0,
                "k": 100,
                "ix": 2
              },
              "o": {
                "a": 0,
                "k": 0,
                "ix": 3
              },
              "m": 1,
              "ix": 2,
              "nm": "Taglia tracciati 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 75,
          "st": 0,
          "bm": 0
        }
      ]
    }
  ],
  "layers": [
    {
      "ddd": 0,
      "ind": 1,
      "ty": 0,
      "nm": "0239_microphone_100pt_#9596AF",
      "ln": "9596AF",
      "refId": "comp_0",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            16,
            16,
            0
          ],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [
            520,
            520,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [
            3.07,
            3.07,
            100
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "w": 1040,
      "h": 1040,
      "ip": 0,
      "op": 75,
      "st": 0,
      "bm": 0
    }
  ],
  "markers": []
}
module.exports = data;