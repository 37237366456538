import Component from '../../libs/component';
import { register } from '../../libs/register';
import {deleteCookie} from '../../libs/utils';


class HeaderLite extends Component {
    
    constructor(container) {
        super('base-header-lite');

        this.header = container;
        this.changeLogin = container.querySelector('.base-header__login-change');

        this._init();
    }

    _init() {
        this.changeLogin.addEventListener('click', () => {
            deleteCookie('loginPage');
        });
    }
}

register.registerClass('.base-header-lite', HeaderLite);