///////////////////////////////////////////////////
//////////// ANALYTICS LIBRARY  ///////////////////
///////////////////////////////////////////////////

class Analytics {

    constructor() {
        this.dataLayer = window['dataLayer'];
    }

    sendData(data) {

        try {
            let jsonData = (typeof data === 'string' || data instanceof String) ? JSON.parse(data) : data;

            if (this.dataLayer && jsonData) {
                console.log('*** Sending Analytics:',jsonData);
                this.dataLayer.push(jsonData);
            } else {
                console.log('--- Debug Analytics:',jsonData);
            }
        } catch(err) {
            console.log('/-/-/- Analytics Error:',err);
        }
    }

}

const defaultAnalytics = new Analytics();
export const analytics = defaultAnalytics;