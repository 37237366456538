const data = {
  "v": "5.6.10",
  "fr": 25,
  "ip": 0,
  "op": 75,
  "w": 32,
  "h": 32,
  "nm": "0255_pdf_scuro_32px",
  "ddd": 0,
  "assets": [
    {
      "id": "comp_0",
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          "nm": "Livello 3 contorni",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                742.303,
                182.388,
                0
              ],
              "ix": 2
            },
            "a": {
              "a": 0,
              "k": [
                97.887,
                97.862,
                0
              ],
              "ix": 1
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6
            }
          },
          "ao": 0,
          "hasMask": true,
          "masksProperties": [
            {
              "inv": false,
              "mode": "a",
              "pt": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "o": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "v": [
                    [
                      0.25,
                      0.25
                    ],
                    [
                      0.25,
                      195.474
                    ],
                    [
                      195.525,
                      195.474
                    ],
                    [
                      195.525,
                      0.25
                    ]
                  ],
                  "c": true
                },
                "ix": 1
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 3
              },
              "x": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "nm": "Maschera 1"
            }
          ],
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -17.975,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          17.975
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -97.637,
                          -97.612
                        ],
                        [
                          -97.637,
                          65.065
                        ],
                        [
                          -65.091,
                          97.612
                        ],
                        [
                          97.637,
                          97.612
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Tracciato 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Riempimento 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      97.887,
                      97.862
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 1,
                    "k": [
                      {
                        "i": {
                          "x": [
                            0.667,
                            0.667
                          ],
                          "y": [
                            1,
                            1
                          ]
                        },
                        "o": {
                          "x": [
                            0.333,
                            0.333
                          ],
                          "y": [
                            0,
                            0
                          ]
                        },
                        "t": 18,
                        "s": [
                          0,
                          0
                        ]
                      },
                      {
                        "t": 19,
                        "s": [
                          100,
                          100
                        ]
                      }
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Trasformazione"
                }
              ],
              "nm": "Gruppo 1",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 75,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          "nm": "Livello 1 contorni",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                520.285,
                520,
                0
              ],
              "ix": 2
            },
            "a": {
              "a": 0,
              "k": [
                570.714,
                690,
                0
              ],
              "ix": 1
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6
            }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -18.617
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -18.617,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          18.617
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -18.617,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          18.617
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          18.617,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          319.715,
                          -280
                        ],
                        [
                          159.715,
                          -440
                        ],
                        [
                          -287.005,
                          -440
                        ],
                        [
                          -320.714,
                          -406.291
                        ],
                        [
                          -320.714,
                          405.291
                        ],
                        [
                          -287.005,
                          440
                        ],
                        [
                          287.005,
                          440
                        ],
                        [
                          320.715,
                          405.291
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Tracciato 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Traccia 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      570.714,
                      690
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Trasformazione"
                }
              ],
              "nm": "Gruppo 1",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": {
                      "x": [
                        1
                      ],
                      "y": [
                        1
                      ]
                    },
                    "o": {
                      "x": [
                        0
                      ],
                      "y": [
                        0
                      ]
                    },
                    "t": 1,
                    "s": [
                      100
                    ]
                  },
                  {
                    "t": 18,
                    "s": [
                      0
                    ]
                  }
                ],
                "ix": 1
              },
              "e": {
                "a": 0,
                "k": 100,
                "ix": 2
              },
              "o": {
                "a": 0,
                "k": 0,
                "ix": 3
              },
              "m": 1,
              "ix": 2,
              "nm": "Taglia tracciati 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 75,
          "st": 0,
          "bm": 0
        }
      ]
    }
  ],
  "layers": [
    {
      "ddd": 0,
      "ind": 1,
      "ty": 0,
      "nm": "0255_pdf_100pt_scuro",
      "refId": "comp_0",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            16,
            16,
            0
          ],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [
            520,
            520,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [
            3.07,
            3.07,
            100
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "w": 1040,
      "h": 1040,
      "ip": 0,
      "op": 75,
      "st": 0,
      "bm": 0
    }
  ],
  "markers": []
}
module.exports = data;