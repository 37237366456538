import Component from '../../libs/component';
import { register } from '../../libs/register';


class MediaVideo extends Component {

    constructor(container) {
        super('media-video');

        this.container = container;
        this.iframe = this.container.querySelector('iframe');
        this.button = this.container.querySelector(this._el('play-button',true));

        if (this.button) {
            if (this.iframe.dataset.src && this.iframe.dataset.src != '') {
                this.button.classList.add('visible');
                this.button.addEventListener('click', () => {
                    this._loadVideo();
                });
            }
        }
    }

    _loadVideo() {
        this.iframe.src = this.iframe.dataset.src;
        this.container.classList.add('video-playing');
    }
}

register.registerClass('.media-video', MediaVideo);