import Component from '../../libs/component';
import { register } from '../../libs/register';
import { analytics } from '../../libs/analytics';
import { setBlockScroll, removeBlockScroll } from '../base-page';


class FormModal extends Component {
    constructor(container) {
        super('widget-modal-form');

        this.modal = container;
        this.modalId = container.id;
        this.modalBody = this.modal.querySelector(this._el('body',true));
        this.modalForm = this.modal.querySelector('.widget-form-steps');
        this.btnClose = container.querySelector(this._el('close',true));
        this._closeModal = this.closeModal.bind(this);

        this._modalInit();
    }

    // Init Modal
    _modalInit() {

        this.modal
            .querySelectorAll(`${this._el('cta',true)}, ${this._el('close',true)}, .elem-cta`)
            .forEach((el) => {
                el.addEventListener('click', (ev) => {
                    this.sendAnalytics('click', ev.target.textContent.trim() || ev.target.getAttribute('aria-label'));
                });
            });

        this.btnClose
            .addEventListener('click', (ev) => {
                ev.preventDefault();
                this._closeModal();
            });
    }

    _openModal() {

        setBlockScroll();
        this.modalBody.scrollTo(0, 0);
        this.modal.classList.add("show");
        this.modal.classList.remove('hide');
        
        if (this.modalForm) {
            const event = new CustomEvent('formStepsInit', { bubbles: true });
            this.modalForm.dispatchEvent(event);
        }

        document.body.addEventListener('modalFormClose', this._closeModal);
        this.sendAnalytics('impression');
    }

    closeModal() {

        this.modal.classList.remove('show');
        this.modal.classList.add('hide');
        removeBlockScroll();

        document.body.removeEventListener('modalFormClose', this._closeModal);

        if (this.modalForm) {
            const event = new CustomEvent('formStepsReset', { bubbles: true });
            this.modalForm.dispatchEvent(event);
        }
    }

    sendAnalytics(action,cta=null) {

        let title = this.modal.querySelector(this._el('title',true)) || this.modal.querySelector(this._el('header-title',true)),
            content = this.modal.querySelector(this._el('step.active',true)) || this.modal.querySelector(this._el('body',true)),
            ctaList = null;

        //if (!cta)    // Creating an array with cta labels
        ctaList = [...this.modal.querySelectorAll(`${this._el('cta',true)}, .elem-cta`)]
                        .filter((el) => {
                            if (el.offsetParent !== null)
                                return true;    // CTA is visible
                            return false;
                        })
                        .map((el) => el.textContent.trim());

        analytics.sendData({
            'event': 'popup',
            'language': document.documentElement.lang,
            'type': null,
            'popup': {
                'action': action,
                'elements': {
                    'title': title ? title.textContent.trim() : null,
                    'content': content ? content.textContent.trim() : null,
                    'name': null,
                    'cta': {
                        'existing': ctaList || null,
                        'clicked': cta
                    }
                }
            }
        });
    }
}

register.registerClass('.widget-modal-form', FormModal);