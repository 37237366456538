import Component from '../../libs/component';
import { register } from '../../libs/register';
import { isIE } from '../../libs/utils';
import ResizeObserver from 'resize-observer-polyfill';



class StickyMenu extends Component {
    
    constructor(container) {
        super('base-sticky-menu');

        this.sticky = container;
        this.stickyItem = container.querySelectorAll(this._el('nav-link',true));
        this.section = document.querySelectorAll('.elem-hidden-section');
        this.MENU_OFFSET = window.innerHeight / 2;

        this._initScrollHide = this.initScrollHide.bind(this);
        this._onScrollHide = this.onScrollHide.bind(this);
        this._checkScrollHide = this.checkScrollHide.bind(this);
        this._cleanScrollHide = this.cleanScrollHide.bind(this);
        this._unhideStickyMenu = this.unhideStickyMenu.bind(this);
        this._disableDesktopScrollHide = this.disableDesktopScrollHide.bind(this);
        this._scrollToSection = this.scrollToSection.bind(this);
        this.ticking = false;
        this.scrolling = false;

        if ((window.deviceBreakpoints.bpDesktop.matches === true) || isIE()) {
            this._addDesktopInit();
        }

        document.addEventListener('bpDesktop', () => {
            this._addDesktopInit();
        });

        this._initScrollHide();

        let basePage = document.querySelector('.base-page');
        if (basePage.classList.contains('sticky-menu--mobile'))
            document.body.classList.add('sticky-menu--mobile-enabled');
    }

    _addDesktopInit() {
        console.log('INIT DESKTOP STICKY MENU');

        this.stickyItem.forEach((el) => {
            if(el.href) {
                el.addEventListener('click', (ev) => { 

                    ev.preventDefault();
                    ev.stopPropagation();

                    if (!this.scrolling) {
                        const body = document.querySelector('body');

                        this._addHeightListener(body,this._scrollToSection,el);

                        this.scrolling = true;
                        this._scrollToSection(el);

                        setTimeout(() => {
                            this._removeHeightListener();   // Temp workaround
                            this.scrolling = false;
                        }, 1500);
                    }
                });
            }
        });

        this._addScrollListener();
    }

    _addHeightListener(elem,callback,param) {

        if (this.resizeObserver)
            this._removeHeightListener();

        this.resizeObserver = new ResizeObserver(() => {
            callback(param);
        });

        this.resizeObserver.observe(elem);
    }

    _removeHeightListener() {

        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
            this.resizeObserver = null;
        }
    }

    _addScrollListener() {
        let sticky = false,
            prevScrollPos = 0,
            lastScrollPos = window.scrollY || window.pageYOffset;

        this._checkScrollPosition(lastScrollPos,prevScrollPos,this.MENU_OFFSET);

        document.addEventListener('scroll', () => {
            lastScrollPos = window.pageYOffset;
            
            if (!sticky) {
                window.requestAnimationFrame( () => {
                    this._checkScrollPosition(lastScrollPos,prevScrollPos,this.MENU_OFFSET);
                    prevScrollPos = lastScrollPos;
                    sticky = false;
                });

                sticky = true;
            }
        });

        window.addEventListener("scroll", this._throttle( () => {
            this._highlightNavigation();
        }, 150));
    }

    _checkScrollPosition(lastPos,prevPos,offset) {

        if (lastPos > offset && lastPos > prevPos) {
            document.body.classList.add('sticky-scrolled');
        } else if (lastPos < offset) {
            document.body.classList.remove('sticky-scrolled');
        }
    }

    scrollToSection(el){
        let value = el.getAttribute('href');

        if (value) {
            value = value.replace("#", "");
            let element = document.getElementById(value);

            if (element) {
                // Get the size and position of our element in the viewport
                //const containerBox = element.getBoundingClientRect();
                // const headerHeight = document.querySelector('header').getBoundingClientRect().height;
                const headerHeight = this.sticky.getBoundingClientRect().height;

                // The top offset of our element is the top position of the 
                // element in the viewport plus the amount the body is scrolled minus the header height
                // let offsetTop = containerBox.top + (window.scrollY || window.pageYOffset) - headerHeight;
                let offsetTop = element.offsetTop - headerHeight;

                //console.debug(offsetTop , headerHeight, (containerBox.top + (window.scrollY || window.pageYOffset) - headerHeight) )

                let scrollOptions = {
                    left: 0,
                    top: offsetTop,
                    behavior: 'smooth'
                }
                
                el.parentElement.classList.add('active');
                    
                window.scrollTo(scrollOptions);
            }
        }
    }

    _throttle(fn, interval) {
        let lastCall, timeoutId;
        return function () {
            let now = new Date().getTime();
            if (lastCall && now < (lastCall + interval) ) {
                // if we are inside the interval we wait
                clearTimeout(timeoutId);
                timeoutId = setTimeout(function () {
                    lastCall = now;
                    fn.call();
                }, interval - (now - lastCall) );
            } else {
                // otherwise, we directly call the function 
                lastCall = now;
                fn.call();
            }
        };
    }

    _highlightNavigation() {
        // get the current vertical position of the scroll bar
	    let scrollPosition = window.pageYOffset || document.documentElement.scrollTop;

        let currentScroll = scrollPosition;
        let currentSection;

        this.section.forEach((el) => {
            let sectionPosition = el.offsetTop - 72;

            if( sectionPosition  <= currentScroll ) {
                currentSection = el;
            }
        });

        // If there is a section 
        if( currentSection != undefined) {
            let id = currentSection.id;

            // console.debug(id);

            this.stickyItem.forEach((el) => {
                
                let value = el.getAttribute('href');
                
                if(value) {
                    value = value.replace('#','');

                    el.parentElement.classList.remove('active');

                    if(id === value) {
                        el.parentElement.classList.add('active');
                    } else {
                        el.parentElement.classList.remove('active');
                    }
                }
                
            });
        }

    }

    initScrollHide() {

        this._cleanScrollHide();

        document.addEventListener('bpDesktop', this._disableDesktopScrollHide);
        document.addEventListener('bpTablet', this._initScrollHide);
        document.addEventListener('bpMobile', this._initScrollHide);

        if (window.deviceBreakpoints.bpDesktop.matches === false) {
            document.addEventListener('scroll', this._onScrollHide);
            window.addEventListener('resize', this._checkScrollHide);
            this._checkScrollHide();
        }
    }

    cleanScrollHide() {
        document.removeEventListener('bpDesktop', this._disableDesktopScrollHide);
        document.removeEventListener('bpTablet', this._initScrollHide);
        document.removeEventListener('bpMobile', this._initScrollHide);
        this._disableDesktopScrollHide();
    }

    disableDesktopScrollHide() {
        document.removeEventListener('scroll', this._onScrollHide);
        window.removeEventListener('resize', this._checkScrollHide);
        this._unhideStickyMenu();
    }

    checkScrollHide() {
        const footer = document.querySelector('footer .base-footer__secondary');
        if (!footer) { return; }
        const footerBox = footer.getBoundingClientRect();
        if (footerBox.top < window.innerHeight) {
            if (!this.sticky.matches(this._mod('hidden',true)))
                this.sticky.classList.add(this._mod('hidden'));
        } else {
            this._unhideStickyMenu();
        }
    }

    onScrollHide() {
        if (!this.ticking) {
            window.requestAnimationFrame( () => {
                this._checkScrollHide();
                this.ticking = false;
            });

            this.ticking = true;
        };
    }

    unhideStickyMenu() {
        this.sticky.classList.remove(this._mod('hidden'));
    }
}

register.registerClass('.base-sticky-menu', StickyMenu);