import LazyLoad from 'vanilla-lazyload';

const lazyLoad = new LazyLoad({
    elements_selector: '.media-picture--lazy .media-picture__img',
    class_loaded: 'media-picture__img--loaded',
    callback_loaded: (img) => {
        img.classList.add('media-picture__img--loaded');
        img.classList.remove('media-picture__fixed-ratio');
        img.removeAttribute('style');
        img.closest('picture').classList.add('media-picture--loaded');
        const event = new CustomEvent('mediaPictureLoaded', { bubbles: true });
        img.dispatchEvent(event);
    },
});

(() => {
    //reload images if adobe target load a dynamic image
    if (window.adobe && window.adobe.target) {
        document.addEventListener(window.adobe.target.event.CONTENT_RENDERING_SUCCEEDED, function () {
            lazyLoad.update();
        });
    }
    lazyLoad.update();
})();

export const forceUpdateLazy = () => {
    lazyLoad.update();
};

export const resetImageLazy = (img) => {

    if (img && img.dataset.llStatus != 'loaded') {
        let pic = img.closest('picture');

        Array.prototype.forEach.call(pic, el => {
            if (el.nodeName === 'SOURCE') {
                el.dataset.srcset = el.srcset;
                el.srcset = '';
            } else {
                el.dataset.src = el.src;
                el.src = 'data:image/gif;base64,R0lGODlhAwACAIAAAP///wAAACH5BAEAAAEALAAAAAADAAIAAAICjF8AOw==';
            }
        });
        
        img.classList.remove('media-picture__img--loaded');
        img.classList.remove('loading');
        img.removeAttribute('data-ll-status');
        pic.classList.remove('media-picture--loaded');
        LazyLoad.resetStatus(img);
        //console.log('Lazy images resetting:', img);
    }
};
