import Component from '../../libs/component';
import { register } from '../../libs/register';
import { forceUpdateLazy } from '../../components/media-picture';
import { getNextSibling, isIE, getCookie} from '../../libs/utils';
import { setBlockScroll, removeBlockScroll } from '../base-page';


class Header extends Component {
    
    constructor(container) {
        super('base-header');

        this.SCROLLED_HIDDEN_MENU_SELECTOR = 'scrolled-hidden';
        this.SCROLLED_MENU_SELECTOR = 'scrolled';
        this.OPENED_MOBILE_MENU_SELECTOR = 'nav--opened';
        this.OPENED_DESKTOP_MENU_SELECTOR = 'flyout--active';
        this.CLOSING_DESKTOP_MENU_SELECTOR = 'flyout--closing';
        this.ACTIVE_NAV_BUTTON_SELECTOR = 'active';
        this.NO_MENU_ANIMATION_SELECTOR = 'no-anim';
        this.ACTIVE_FLYOUT_MENU_SELECTOR = 'flyout--active';
        this.FLYOUT_OPENED_SELECTOR = 'flyout--opened';
        this.FLYOUT_CLOSING_SELECTOR = 'flyout--closing';
        this.FLYOUT_COLUMNS_BALANCE = 'column-balance';
        this.FLYOUT_HIGHLIGHT = 'highlight';
        this.FLYOUT_MAX_COLUMN_ITEMS = 99;
        this.MENU_CLOSING_DELAY = 500;
        this.MOBILE_SCROLL_OFFSET =
            parseInt( getComputedStyle(document.documentElement).getPropertyValue('--header-height').replace('px','') ) || 72;
        this.MENU_DARK = 'base-header--dark';
        this.MENU_DEFAULT = 'base-header--white';
        this.MENU_OPEN ='menu-open';
        this.MENU_STICKY ='base-header--sticky-menu';

        this.header = container;
        this.navMenu = this.header.querySelector('nav');
        this.navMenuItems = this.navMenu.querySelector(this._el('nav-items',true)).children;
        this.menuOpened = false;
        this.menuTimer = null;

        if (this.header.classList.contains(this.MENU_STICKY))
            this.MENU_STICKY_OFFSET = window.innerHeight / 2;

        this._addCommonInit();

        if (isIE())
            this._addDesktopInit();
        else
            window.deviceBreakpoints.bpDesktop.matches === true ? this._addDesktopInit() : this._addMobileInit();
    }

    _addCommonInit() {
        console.log('INIT HEADER COMMONS');

        let mobileRotation = window.matchMedia('(orientation: landscape)');

        mobileRotation.addListener(() => {
            if (this.menuOpened)
                this._closeMobileMenu();
        });

        document.addEventListener('bpDesktop', () => {
            this._cleanMobileMenu();
            this._cleanDesktopMenu();
            this._addDesktopInit();
        });

        document.addEventListener('bpMobileTablet', () => {
            this._cleanDesktopMenu();
            this._cleanMobileMenu();
            this._addMobileInit();
        });

        //this._checkColumnElements(this.navMenu);
        this._addScrollListener();
        this._cookieInit();
    }

    _addMobileInit() {
        console.log('INIT HEADER MOBILE MENU');

        this.navMenu
            .querySelectorAll('li.has-submenu > button, li.has-submenu > label')
            .forEach((el) => {

                el.addEventListener('click', (ev) => {
                    let item = ev.target.parentElement;
                    
                    item.parentElement
                        .querySelectorAll('li')
                        .forEach((li) => {
                            li.classList.remove(this.FLYOUT_OPENED_SELECTOR);
                        });
                    
                    item.classList.add(this.FLYOUT_OPENED_SELECTOR);

                    let left = this.navMenu.style.left.replace('%','') | 0;
                    let offset = parseInt(left) - 100;
                    this.navMenu.style.left = `${offset}%`;
                    this.navMenu.classList.add(this.ACTIVE_FLYOUT_MENU_SELECTOR);
                });
            });

        this.navMenu
            .querySelectorAll(`.${this.name}__back-btn`)
            .forEach((el) => {
                el.addEventListener('click', () => {

                    let left = this.navMenu.style.left.replace('%','') | 0;
                    let offset = parseInt(left) + 100;
                    this.navMenu.style.left = `${offset}%`;

                    if (offset == 0)
                        this.navMenu.classList.remove(this.ACTIVE_FLYOUT_MENU_SELECTOR);
                });
            });

        this.header
            .querySelectorAll(`${this._el('menu-btn',true)}, ${this._el('menu-close-btn',true)}`)
            .forEach((el) => {
                el.addEventListener('click', (ev) => {
                    ev.preventDefault();

                    if (this.header.classList.contains(this.OPENED_MOBILE_MENU_SELECTOR)) {
                        this._closeMobileMenu();
                    } else {
                        this._openMobileMenu();
                    }
                });
            });
    }

    _cleanMobileMenu() {
        console.log('CLEANING HEADER MOBILE MENU');

        this._closeMobileMenu();

        this.navMenu
            .querySelectorAll('li.has-submenu > button, li.has-submenu > label')
            .forEach((el) => el.replaceWith(el.cloneNode(true)));

        this.navMenu
            .querySelectorAll(`.${this.name}__back-btn`)
            .forEach((el) => el.replaceWith(el.cloneNode(true)));

        this.header
            .querySelectorAll(`${this._el('menu-btn',true)}, ${this._el('menu-close-btn',true)}`)
            .forEach((el) => el.replaceWith(el.cloneNode(true)));
    }

    _addDesktopInit() {
        console.log('INIT HEADER DESKTOP MENU');

        if (this.navMenu) {

            let navMenus = this.navMenu
                .querySelectorAll(this._el('nav-item.has-submenu',true));
            
            navMenus.forEach((el) => {

                el.addEventListener('click', (ev) => {
                    this._handleNavButton(ev);
                });
                /*el.addEventListener('mouseover', (ev) => {
                    this._handleNavButton(ev);
                });
                el.addEventListener('mouseout', (ev) => {
                    this._handleNavButton(ev);
                });*/
            });

            let flyoutButtons = this.navMenu
                .querySelectorAll(this._el('flyout-item.has-submenu',true));

            flyoutButtons.forEach((el) => {

                el.addEventListener('mouseover', (ev) => {
                    this._handleFlyoutButton(ev);
                });
                el.addEventListener('click', (ev) => {
                    this._handleFlyoutButton(ev);
                });
                el.addEventListener('mouseout', (ev) => {
                    this._handleFlyoutButton(ev);
                });
            });

            let flyoutLinks = this.navMenu
                .querySelectorAll(`ul.second-level > li > ${this._el('flyout-link',true)}`);

            flyoutLinks.forEach((el) => {

                el.addEventListener('mouseover', (ev) => {
                    this._handleFlyoutLink(ev);
                });
                el.addEventListener('mouseout', (ev) => {
                    this._handleFlyoutLink(ev);
                });
            });

            let flyoutMenus = this.navMenu
                .querySelectorAll(`${this._el('flyout',true)}`);

            flyoutMenus.forEach((el) => {
                
                el.addEventListener("animationend", (ev) => {
                    //console.log('*** ANIM ENDED',ev);
                    
                    if (ev.animationName == 'flyoutUp') {
                        ev.target
                            .closest(this._el('nav-item.has-submenu',true))
                            .classList
                            .remove(this.FLYOUT_CLOSING_SELECTOR);
                        this.menuAnimating = false;
                        this.menuOpened = false;
                        this._resetMenu();
                        
                        document.body.classList.remove(this.MENU_OPEN);

                        console.log('--- HEADER MENU CLOSED');
                    }
                });
            });

            let flyoutPreview = this.navMenu
                .querySelectorAll(this._el('flyout-item.has-preview',true));

            flyoutPreview.forEach((el) => {
                
                el.addEventListener('mouseover', (ev) => {
                    this._handleFlyoutPreview(ev);
                });

                el.addEventListener('mouseout', (ev) => {
                    this._handleFlyoutPreview(ev);
                });
            });

            this.header
                .querySelectorAll(`${this._el('menu-close-btn',true)}`)
                .forEach((el) => {
                    el.addEventListener( 'click', (ev) => {
                        ev.preventDefault();
                        this._flyoutMenuClose();
                    });
                });

            forceUpdateLazy();
        }
    }

    _cleanDesktopMenu() {
        console.log('CLEANING HEADER DESKTOP MENU');

        let navMenus = this.navMenu.querySelectorAll(this._el('nav-item.has-submenu',true));
        navMenus.forEach((el) => el.replaceWith(el.cloneNode(true)));

        let flyoutButtons = this.navMenu.querySelectorAll(this._el('flyout-item.has-submenu',true));
        flyoutButtons.forEach((el) => el.replaceWith(el.cloneNode(true)));

        let flyoutLinks = this.navMenu
            .querySelectorAll(`${this._el('flyout--vertical',true)} > ul > li > ${this._el('flyout-link',true)}`);
        flyoutLinks.forEach((el) => el.replaceWith(el.cloneNode(true)));

        let flyoutMenus = this.navMenu.querySelectorAll(`${this._el('flyout',true)}`);
        flyoutMenus.forEach((el) => el.replaceWith(el.cloneNode(true)));

        this.header
            .querySelectorAll(`${this._el('menu-close-btn',true)}`)
            .forEach((el) => el.replaceWith(el.cloneNode(true)));
    }

    _handleNavButton(ev) {

        let { target: el } = ev;

        if (el.classList.contains(`${this._el('nav-button')}`)) {

            if (!el.classList.contains(this.ACTIVE_NAV_BUTTON_SELECTOR)) {
                if (!this.menuOpened) {

                    this.menuOpened = true;
                    this.navMenu
                        .classList
                        .add(this.OPENED_DESKTOP_MENU_SELECTOR);

                    this._addClickOutside();
                    this._addScrollCloseMenu();
                    
                    console.log('*** HEADER MENU OPENED')

                    document.body.classList.add(this.MENU_OPEN);

                } else {
                    this._closeFlyout(this.navMenu);
                    this.navMenu
                        .classList
                        .add(this.NO_MENU_ANIMATION_SELECTOR);
                }

                // show first preview
                if (ev.target.closest(`${this._el('nav-item',true)}`).querySelector('.has-preview')) {
                    this._openFlyout(ev.target.closest(`${this._el('nav-item',true)}`).querySelector('.has-preview'));
                }
                
                this._cleanFlyoutItems();
                this._cleanNavButtons();
                this._openFlyout(ev.target.closest(`${this._el('nav-item',true)}`));
                this._checkNavHeight(ev.target.closest(`${this._el('nav-item',true)}`));

                ev.target
                    .classList
                    .add(this.ACTIVE_NAV_BUTTON_SELECTOR);

            } else {
            
                this._flyoutMenuClose();
            }
        }
    }

    _handleFlyoutButton(ev) {
        ev.stopPropagation();

        let { target: el } = ev;

        if (el.classList.contains(`${this._el('flyout-button')}`)) {

            if (ev.type === 'click' || ev.type === 'mouseover') {
                this._closeFlyout(el.closest(`${this._el('flyout',true)}`));
                this._openFlyout(el.closest(`${this._el('flyout-item',true)}`));
                this._setFlyoutHeight(getNextSibling(el,`${this._el('flyout',true)}`));
                this._cleanFlyoutItems();
                this._hightlightItem(el);
            }
        }
    }

    _handleFlyoutLink(ev) {

        const { target: el, type: type } = ev;
        
        switch(type) {
            case 'mouseover':
                this._cleanFlyoutItems();
                this._hightlightItem(el);
                this._closeFlyout(el.closest(`${this._el('flyout',true)}`));
                break;
            case 'mouseout':
                this._hightlightItem(el,false);
                break;
        }
    }

    _handleFlyoutPreview(ev) {
        
        const { target: el, type: type } = ev;

        if (el.classList.contains(`${this._el('flyout-link')}`)) {
            if (type == 'mouseover') {
                this._closeFlyout(el.closest(`${this._el('flyout-items',true)}.second-level`));
                this._openFlyout(el.closest(`${this._el('flyout-item',true)}`));
                this._cleanFlyoutItems();
            }
        }
    }

    _hightlightItem(item,enable=true) {

        const flyoutItems = item.closest(`${this._el('flyout-items',true)}`);

        if (enable) {
            item.classList.add(this.FLYOUT_HIGHLIGHT);
            flyoutItems.classList.add(this.FLYOUT_HIGHLIGHT);
        } else {
            item.classList.remove(this.FLYOUT_HIGHLIGHT);
            flyoutItems.classList.remove(this.FLYOUT_HIGHLIGHT);
        }
    }

    _cleanFlyoutItems() {

        this.navMenu
            .querySelectorAll(`.${this.FLYOUT_HIGHLIGHT}`)
            .forEach((el) => {
                el.classList
                    .remove(this.FLYOUT_HIGHLIGHT);
            });
    }

    _cleanNavButtons() {

        this.navMenu
            .querySelectorAll(`${this._el('nav-item',true)}`)
            .forEach((el) => {
                el.classList
                    .remove(this.FLYOUT_CLOSING_SELECTOR);
                
                if(el.childElementCount > 2)
                    el.querySelector(`${this._el('nav-button',true)}`)
                        .classList
                        .remove(this.ACTIVE_NAV_BUTTON_SELECTOR);
            });
    }

    _addScrollListener() {

        let ticking = false,
            prevScrollPos = 0,
            lastScrollPos = window.scrollY || window.pageYOffset;

        this._checkScrollPosition(lastScrollPos,prevScrollPos,this.MOBILE_SCROLL_OFFSET);

        document.addEventListener('scroll', () => {
            lastScrollPos = window.pageYOffset;

            if (!ticking) {
                window.requestAnimationFrame( () => {
                    this._checkScrollPosition(lastScrollPos,prevScrollPos,this.MOBILE_SCROLL_OFFSET);
                    prevScrollPos = lastScrollPos;
                    ticking = false;
                });

                ticking = true;
            }
        });
    }

    _addScrollCloseMenu() {
        document.addEventListener('scroll', this._scrollCloseMenu);
    }

    _scrollCloseMenu() {
        let menu = document.querySelector('header').objReference;
        let closeMenu = menu._flyoutMenuClose.bind(menu);
        closeMenu();
        document.removeEventListener('scroll', menu._scrollCloseMenu);
    }

    _checkScrollPosition(lastPos,prevPos,offset=0) {

        if (lastPos > 1) {  // Accounting for rounding error
            this.header.classList.add(this.SCROLLED_MENU_SELECTOR);
            this._setStyle();
        } else {
            this.header.classList.remove(this.SCROLLED_MENU_SELECTOR);
            this._resetStyle();
        }

        // If there isn't sticky menu, header is hidden on scroll down and is showed on scroll up
        if (lastPos > offset && lastPos > prevPos) {
            this.header.classList.add(this.SCROLLED_HIDDEN_MENU_SELECTOR);
        } else {
            this.header.classList.remove(this.SCROLLED_HIDDEN_MENU_SELECTOR);
        }

        if (this.header.classList.contains(this.MENU_STICKY)) {

            let stickyOffset = offset;

            if (window.deviceBreakpoints.bpDesktop.matches === true)
                stickyOffset = this.MENU_STICKY_OFFSET;

            if (lastPos > stickyOffset && lastPos > prevPos) {
                this.header.classList.add(this.SCROLLED_HIDDEN_MENU_SELECTOR);
            } else {
                this.header.classList.remove(this.SCROLLED_HIDDEN_MENU_SELECTOR);
            }
        }
    }

    _flyoutMenuClose(withDelay=false) {

        let navItem = this.navMenu.querySelector(`${this._el('nav-item.flyout--opened',true)}`);

        if (navItem) {

            if (withDelay) {

                this._closeDelay();
                return;
            }

            this.navMenu
                .classList
                .remove(this.NO_MENU_ANIMATION_SELECTOR);

            navItem.querySelector('button, label').classList.remove('active');
            navItem.classList.remove(this.FLYOUT_OPENED_SELECTOR);
            navItem.classList.add(this.FLYOUT_CLOSING_SELECTOR);
            this.navMenu.classList.add(this.CLOSING_DESKTOP_MENU_SELECTOR);
            this._removeClickOutside();
            this.menuAnimating = true;
            
            if (this.header.classList.contains('nav-scrollable')) {
                removeBlockScroll();
                this.header.classList.remove('nav-scrollable');
            }
        }
    }

    _closeDelay() {

        let activeFlyout = this.navMenu.querySelector(`${this._el('flyout-item.flyout--opened',true)} ${this._el('flyout',true)}`)

        this.clearTimer = () => {
            //console.log('/// clear time?')
            if (this.menuTimer) {
                //console.log('/-/-/- timer cleared')
                clearTimeout(this.menuTimer);
                this.menuTimer = null;
                
                if (activeFlyout)
                    activeFlyout.removeEventListener('mouseover', this.clearTimer);

                this.navMenu.removeEventListener('mouseover', this.clearTimer);
            }
        };

        if (this.menuTimer)
            this.clearTimer();

        //activeFlyout.addEventListener('mouseover', (ev) => console.log('ciao',ev));
        
        if (activeFlyout)
            activeFlyout.removeEventListener('mouseover', this.clearTimer);

        this.navMenu.addEventListener('mouseover', this.clearTimer);

        //console.log('/+/+/+ delayed close requested')

        this.menuTimer = setTimeout( () => {
            this.clearTimer();
            this._flyoutMenuClose();
        }, this.MENU_CLOSING_DELAY);
    }
    
    _addClickOutside() {
        document.body.addEventListener('click', this._clickOutside);
    }

    _removeClickOutside() {
        document.body.removeEventListener('click', this._clickOutside);
    }

    _clickOutside(ev) {

        let header = document.querySelector('header'),
            flyout = header.querySelector('li.has-submenu.flyout--opened'),
            langMenu = header.querySelector('[class*=lang-menu]');

        if (flyout) {
            if (!flyout.contains(ev.target)) {
                console.log('CLICKED OUTSIDE',ev.target);
                header
                    .objReference
                    ._flyoutMenuClose();
            }
        }

        if (langMenu) {
            if (!langMenu.contains(ev.target)) {
                langMenu
                    .classList
                    .remove('active');
            }
        }
    }

    _openFlyout(container) {
        if (container) {
            container
                .classList
                .add(this.FLYOUT_OPENED_SELECTOR);
            this._cleanFlyoutHeight();
        }
    }

    _closeFlyout(container) {
        if (container) {
            container
                .querySelectorAll(`.${this.FLYOUT_OPENED_SELECTOR}`)
                .forEach((el) => {
                    el.classList.remove(this.FLYOUT_OPENED_SELECTOR);
                });
        }
    }

    _checkNavHeight(container){

        if (container) {
            let flyoutContainer = container.querySelector(`${this._el('flyout-container',true)}`),
                screenHeight = window.innerHeight,
                containerHeight = flyoutContainer.offsetHeight;

            if( containerHeight > screenHeight ) {
                console.debug('check menu height', flyoutContainer, containerHeight,  screenHeight );
                this.header.classList.add('nav-scrollable');

                setBlockScroll();
                document.removeEventListener('scroll', this.header._scrollCloseMenu);
            }
        }
    }

    _setFlyoutHeight(container) {
        
        if (container) {

            let flyoutItems = container.closest(`${this._el('flyout-items',true)}`),
                screenHeight = window.innerHeight - this.header.offsetHeight,
                items = container.querySelectorAll('li'),
                itemHeight = 100;

                for (const [i, el] of items.entries()) {
                    if (i >= this.FLYOUT_MAX_COLUMN_ITEMS) break;
                    itemHeight += Math.ceil(el.getBoundingClientRect().height);
                }

                if (itemHeight > screenHeight)
                    itemHeight = screenHeight;

                flyoutItems.style.minHeight = itemHeight + 'px';
        }
    }

    _cleanFlyoutHeight(container) {
        
        let flyoutItems;

        if (container)
            flyoutItems = container.querySelectorAll(`${this._el('flyout-items',true)}`);
        else
            flyoutItems = this.header.querySelectorAll(`${this._el('flyout-items',true)}`);

        flyoutItems.forEach((el) => {
            el.style.minHeight = '';
        });
    }

    _openMobileMenu() {

        this.header.classList.add(this.OPENED_MOBILE_MENU_SELECTOR);
        setBlockScroll();
    }

    _closeMobileMenu() {

        if (this.header.classList.contains(this.OPENED_MOBILE_MENU_SELECTOR)) {
            this._resetMenu();
            removeBlockScroll();
        }
    }

    _checkColumnElements(container) {

        if (container) {

            const itemsContainers = [...container.querySelectorAll(`${this._el('flyout-items',true)}.second-level`)];

            itemsContainers.forEach((el) => {

                const items = [...el.children].filter((element) => {
                    return element.classList.contains(this._el('flyout-item')) && !element.classList.contains('is-related-menu') && !element.classList.contains('is-card-menu');
                });
    
                if (items.length > 5)
                    el.classList.add(this.FLYOUT_COLUMNS_BALANCE);
            });
        }
    }

    _setStyle() {

        if (this.header.classList.contains(this.MENU_DARK)) {
            this.header.classList.add(this.MENU_DEFAULT);
        }; 
    }

    _resetStyle(){

        if (this.header.classList.contains(this.MENU_DARK)) {
            this.header.classList.remove(this.MENU_DEFAULT);
        };
    }

    _resetMenu() {

        //if (!this.menuAnimating) {
            this.navMenu.classList.remove(this.OPENED_DESKTOP_MENU_SELECTOR);
            this.navMenu.classList.remove(this.CLOSING_DESKTOP_MENU_SELECTOR);
            this.menuAnimating = false;
            this.menuOpened = false;
        //}

        this.navMenu.classList.remove(this.NO_MENU_ANIMATION_SELECTOR);
        this.header.classList.remove(this.SCROLLED_HIDDEN_MENU_SELECTOR);
        this.header.classList.remove(this.OPENED_MOBILE_MENU_SELECTOR);
        //this.navMenu.classList.remove(this.ACTIVE_FLYOUT_MENU_SELECTOR);
        this.navMenu.style.left = '';
        this._closeFlyout(this.navMenu);
        this._cleanNavButtons();
        this._cleanFlyoutItems();
    }

    _cookieInit() {
        const loginCta = this.navMenu.querySelector(this._el('login-cta',true));

        if (loginCta)
            loginCta.addEventListener('click', (ev) => {
                this._checkLoginCookie(ev);
            });
    }

    _checkLoginCookie(event) {
        const loginPage = getCookie("loginPage");

        if (loginPage != "") {
            event.preventDefault();

            window.location.replace(loginPage);
        } 
    }
}

register.registerClass('.base-header', Header);