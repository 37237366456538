
export const vatCodeCheck = function(vatCode) {

    let s = 0;

    for (let i = 0; i <= 9; i += 2 ) {
        s += vatCode.charCodeAt(i) - '0'.charCodeAt(0);
    }

    for (let i = 1; i <= 9; i += 2 ) {
        let c = 2 * ( vatCode.charCodeAt(i) - '0'.charCodeAt(0) );
        if (c > 9)
            c = c - 9;
        s += c;
    }

    return ((10 - s%10) % 10 === vatCode.charCodeAt(10) - '0'.charCodeAt(0));
}

export const fiscalCodeCheck = function(fiscalCode) {
    let regex = /^(?=.*?\d)(?=.*?[a-zA-Z])[a-zA-Z\d]{16}$/g;

    return regex.test(fiscalCode);
}
