import Component from '../../libs/component';
import { register } from '../../libs/register';
import { setCookie, deleteCookie } from '../../libs/utils';

class Prelogin extends Component {
    constructor(container) {
        super('layout-prelogin');

        this.prelogin = container;
        this.preloginMain = container.querySelector(this._el('main',true));
        this.footerBtn = container.querySelector(this._el('footer-btn',true));
        this.footerCollapse = container.querySelector(this._el('footer-collapse',true));
        this.footerState =  this.footerCollapse.dataset.collapse;
        
        
        this._init();
        this._setFooter();
    }

    _init() {
        this.preloginMain.querySelectorAll('.elem-login-card__link')
            .forEach((el) => {

                el.addEventListener('click', (ev) => {
                    this._setLoginCookie(ev);
                });
            });

        this.footerCollapse.querySelectorAll('.elem-login-card__link')
            .forEach((el) => {

                el.addEventListener('click', () => {
                   deleteCookie('loginPage');
                });
            });
    }

    _setFooter() {
        this.footerBtn.addEventListener('click', (ev) => {
            ev.preventDefault();
            this._expandFooter(ev);
        });
    }

    _expandFooter() {
        if ( this.footerState === "true") {
            this._closeFooter();
        } else {
            this._openFooter();
        }
    }

    _openFooter() {
        let _height =  this.footerCollapse.querySelector('.layout-prelogin__footer-content').scrollHeight;

        this.footerCollapse.style.height = _height + 'px';
        this.footerCollapse.classList.add('show');
        this.footerBtn.setAttribute('aria-expanded', 'true');
        this.footerCollapse.setAttribute('data-collapse', 'true');
        this.footerState = this.footerCollapse.dataset.collapse;
        
        this.preloginMain.style.overflowY = 'hidden';
        
        window.setTimeout(function(){
            document.querySelector('.layout-prelogin__footer-collapse').style.height = null;
        }, 400);
    }

    _closeFooter() {
        let _height =  this.footerCollapse.querySelector('.layout-prelogin__footer-content').scrollHeight;
        
        this.footerCollapse.style.height = _height + 'px';
        this.footerCollapse.classList.remove('show');

        window.setTimeout(function(){
            document.querySelector('.layout-prelogin__footer-collapse').style.height = null;
        },100);

        this.footerBtn.setAttribute('aria-expanded', 'false');
        this.footerCollapse.setAttribute('data-collapse', 'false');
        this.footerState = this.footerCollapse.dataset.collapse;
        
        this.preloginMain.style.overflowY = 'auto';
    }

    _setLoginCookie(ev) {
        let path = ev.target.getAttribute('href');

        let path1 =  "https://icbp.seceti.it/ICBP-CORPORATE/preparaLogin.do;jsessionid=Ev5UHvX1XuLu7ohIxGot5CQ3.wfatas1";
        let path2 = "https://all4you.icbpi.it/psd?portalenexi";
        let path3 = "https://servizi.cartasi.it/platea/homePubblica.do";
    
        // eslint-disable-next-line no-empty
        if (path == path1 || path == path2 || path == path3) {
        } else {
            setCookie('loginPage', path, 365);
        }
    };
}

register.registerClass('.layout-prelogin',  Prelogin);