import Component from '../../libs/component';
import { register } from '../../libs/register';

class ApiDocumentation extends Component {

    constructor(container) {
        super('widget-api-documentation');

        this.apiDocumentationId = container.id;
        this.oasFilePath = container.getAttribute('data-oas-file-path');

        window.addEventListener('load', () => this._createSwaggerUi() );
    }

    _createSwaggerUi() {
        if (window.SwaggerUIBundle) {
            const swaggerUi = window.SwaggerUIBundle({
                url: window.location.origin + this.oasFilePath,
                dom_id: '#' + this.apiDocumentationId,
            });
            window.swaggerUiInstances.push(swaggerUi);
        } else {
            console.error('Missing Swagger UI library');
        }
    }

}

register.registerClass('.widget-api-documentation', ApiDocumentation);