import Component from '../../libs/component';
import { register } from '../../libs/register';
import { addOneTimeEventListener } from '../../libs/utils';



class CustomSelect extends Component {
    
    constructor(container) {
        super('elem-custom-select');

        this.container = container;
        this.select = container.querySelector('select');
        this.type = this.select.dataset.type;
        this.filterSelect = container.classList.contains("list-filter");
        this.formSelect = container.classList.contains("form-select");
        this.localizedLabels = this.container.dataset.i18n ? JSON.parse(this.container.dataset.i18n) : { selectedLabel: 'selected', applyButton: 'apply'};
        this.container.removeAttribute('i18n');
        this.validated = [];
        this.defaultConfig = {
            panelItem: {
                position: 'bottom',
                item: '<span></span>'
            }
        };
        this.filterConfig = {
            panelItem: {
                position: 'bottom',
                item: `<button class="custom-select__apply-btn">${this.localizedLabels.applyButton}<span></span></button>`
            },
            changeOpenerText: false,
            multipleSelectionOnSingleClick: true,
            multipleSelectOpenerText: { labels: false, array: false },
        };

        if (this.filterSelect || this.formSelect) {

            this.select.addEventListener('change', (ev) => {
                
                const selectedOptions = this._getSelectedOptions(ev.target);

                this._selectActive(selectedOptions.length);
                if (this.filterSelect)
                    this._updateApplyButton(selectedOptions.length);
                this._changeSelectLabel(selectedOptions);

                console.log(selectedOptions)
            });

            this._init();

        } else {

            if (window.deviceBreakpoints.bpDesktop.matches === true)
                this._init();

            window.addEventListener('resize', () => {
                if (window.deviceBreakpoints.bpDesktop.matches === true)
                    this._init();
                else
                    this._clean();
            });
        }
    }
    async _init() {
        const Select = await import('select-custom');
        if (!this.selectCustom) {

            let config = this.filterSelect ? this.filterConfig : this.defaultConfig

            this.selectCustom = new Select.default(this.select, config);

            this.selectCustom.onOpen = () => {
                this._sendOpenEvent();
                // this._addScrollCloseMenu();
                if (this.scrollbar)
                    this.scrollbar.update();
            };

            if (this.filterSelect)
                this.selectCustom.onClose = () => {
                    this._resetValidatedValues();
                };

            if (this.formSelect)
                this.selectCustom.onClose = () => {
                    this._sendCloseEvent();
                };

            this.selectCustom.init();

            this.applyBtn = this.container.querySelector('.custom-select__apply-btn');

            if (this.applyBtn) {
                this.applyBtn.disabled = true;
                this.applyBtn.setAttribute('disabled','');

                this.applyBtn.addEventListener('click',() => {

                    this.validated = this._getSelectedOptions(this.select);
                    this._sendValidatedFiltersEvent({type: this.type, validated: this.validated});

                    //console.log('APPLY',this.validated);

                    // call service
                    this._closeSelect();
                });
            }

            this._addCustomScrollbar();

            // get query string parameters and update select values
        }
    }

    async _addCustomScrollbar() {

        const scrollbar = this.container.querySelector('.custom-select__options');

        if (scrollbar) {
            const PerfectScrollbar = await import('perfect-scrollbar');
            this.scrollbar = new PerfectScrollbar.default(scrollbar, {
                //scrollYMarginOffset: '11',
                suppressScrollX: true,
                wheelPropagation: false,
                swipeEasing: true
            });

            this.scrollbar.update();

            const scrollbarRail = scrollbar.querySelector('.ps__rail-y');
            scrollbarRail.addEventListener('click', (ev) => {
                ev.stopImmediatePropagation();  // Disable select closing on scrollbar click
            });
        }
    }

    _removeCustomScrollBar() {

        if (this.scrollbar) {
            this.scrollbar.destroy();
            this.scrollbar = null;
        }
    }

    _getSelectedOptions(select) {

        return Array(...select.options).reduce((acc, option) => {
            if (option.selected === true && option.value !== 'placeholder') {
                acc.push(option.value);
            }
            return acc;
        }, []);
    }

    _selectActive(len) {

        if (len > 0)
            this.container.classList.add('active');
        else
            this.container.classList.remove('active');
    }

    _updateApplyButton(num) {

        if (this.applyBtn) {

            if (num) {
                this._enableApplyBtn(true,num);
            } else {
                if (!this.validated.length)
                    this._resetApplyBtn();
                else
                    this._enableApplyBtn(true,'');
            }
        }
    }

    _enableApplyBtn(enable=true,label) {

        let text = this.applyBtn.querySelector('span');

        if (label !== undefined && label !== null)
            text.textContent = label !== '' ? ` (${label})` : '';
        
        enable ? this.applyBtn.removeAttribute('disabled') : this.applyBtn.setAttribute('disabled','');
        this.applyBtn.disabled = !enable;
    }

    _resetApplyBtn() {

        this._enableApplyBtn(false,'');
    }

    _changeSelectLabel(selected) {

        let selectLabel = this.container.querySelector('.custom-select__opener');
        let placeholder = this.select.querySelector('option[value="placeholder"]').textContent;
        let newLabel = placeholder;

        if (selected.length === 1) {
            newLabel = this.select.querySelector(`option[value="${selected[0]}"]`).textContent;;
        } else if (selected.length > 1) {
            newLabel = `${selected.length} ${placeholder} ${this.localizedLabels.selectedLabel}`;
        }

        if(selectLabel)
            selectLabel.textContent = newLabel;
    }

    getSelectType() {
        return this.type;
    }

    getValidatedFilters() {
        return this.validated;
    }

    setValidatedFilters(data) {

        this.resetSelect(true);

        data.forEach((val) => {
            let option = this.container.querySelector(`.custom-select__option[data-value="${val}"]`);
            if (option)
                option.click();
        });

        this.applyValidatedFilters();
        this._enableApplyBtn(false);
    }

    applyValidatedFilters() {
        this.validated = this._getSelectedOptions(this.select);
        this._selectActive(this.validated.length);
        this._updateApplyButton(this.validated.length);
        this._changeSelectLabel(this.validated);
        return this.validated;
    }

    _resetValidatedValues() {

        this.resetSelect(false);

        if (this.validated.length > 0) {

            this.validated.forEach((val) => {
                const option = this.container.querySelector(`.custom-select__option[data-value="${val}"]`);
                if (option)
                    option.click();
            })

            this._updateApplyButton(this.validated.length);
            this._changeSelectLabel(this.validated);
        }
    }

    _sendOpenEvent() {
        const event = new CustomEvent('selectOpen', { bubbles: true });
        this.container.dispatchEvent(event);
    }

    _sendCloseEvent() {
        const event = new CustomEvent('selectClosed', { bubbles: true });
        this.container.dispatchEvent(event);
    }

    _sendValidatedFiltersEvent(data) {
        const event = new CustomEvent('filtersValidated', { bubbles: true, detail: { selectValidated: data } });
        this.container.dispatchEvent(event);
    }

    _addScrollCloseMenu() {
        addOneTimeEventListener(document,'scroll', () => {
            this._closeSelect();
        });
    }

    _closeSelect() {
        if (this.container.querySelector('.custom-select--open'))
            this.container.querySelector('.custom-select__opener').click();
    }

    _clean() {

        if (this.selectCustom) {
            this.selectCustom.destroy();
            this.selectCustom = null;
            this._removeCustomScrollBar();
        }
    }

    resetSelect(hardreset=true) {

        let selected = this.container.querySelectorAll('.custom-select__option--selected');

        selected.forEach((el) => {
            el.classList.remove('custom-select__option--selected');
        });

        if (hardreset)
            this.validated = [];

        this.select.selectedIndex = 0;
        this.select.selectedValue = "";

        let event = new CustomEvent('change', { bubbles: true });
        this.select.dispatchEvent(event);
    }

    // Close Country select if click outside
    _addClickOutside() {

        let handler = ({target}) => {
            if (!this.container.contains(target)) {
                this._closeSelect();
            }
        };

        document.body.addEventListener('mousedown', handler);
    }

    // Reset Country select, when is not visible in viewport, or close it if it's open
    _scrollReset() {
        
        if(window.IntersectionObserver) {
            let target = this.select;

            let observer = new IntersectionObserver((entries) => {

                this.validated = this._getSelectedOptions(this.select);

                entries.forEach(entry => {
                    if(entry.intersectionRatio!=1 && this.validated.length > 0){

                        this.resetSelect();
                        this._changeSelectLabel(this.validated);

                    } else if (entry.intersectionRatio!=1 && this.container.querySelector('.custom-select--open') ) {
                        this._closeSelect();
                    }
                });
            }, {threshold: 1});
            observer.observe(target) ;
        }
    }
}

register.registerClass('.elem-custom-select', CustomSelect);