const data = {
  "v": "5.1.13",
  "fr": 25,
  "ip": 0,
  "op": 25,
  "w": 72,
  "h": 72,
  "nm": "0176_download_#ffffff_72px",
  "ddd": 0,
  "assets": [
    {
      "id": "comp_0",
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          "nm": "freccia punta",
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "n": [
                    "0p833_0p833_0p167_0p167"
                  ],
                  "t": 15.833,
                  "s": [
                    100
                  ],
                  "e": [
                    0
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "n": [
                    "0p833_0p833_0p167_0p167"
                  ],
                  "t": 16.667,
                  "s": [
                    0
                  ],
                  "e": [
                    0
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "n": [
                    "0p833_0p833_0p167_0p167"
                  ],
                  "t": 17.5,
                  "s": [
                    0
                  ],
                  "e": [
                    100
                  ]
                },
                {
                  "t": 18.3333333333333
                }
              ],
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": 0.833,
                    "y": 0.833
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0.167
                  },
                  "n": "0p833_0p833_0p167_0p167",
                  "t": 10,
                  "s": [
                    520,
                    520,
                    0
                  ],
                  "e": [
                    520,
                    1520,
                    0
                  ],
                  "to": [
                    0,
                    166.66667175293,
                    0
                  ],
                  "ti": [
                    0,
                    140,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.833,
                    "y": 0.833
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0.167
                  },
                  "n": "0p833_0p833_0p167_0p167",
                  "t": 16.667,
                  "s": [
                    520,
                    1520,
                    0
                  ],
                  "e": [
                    520,
                    -320,
                    0
                  ],
                  "to": [
                    0,
                    -140,
                    0
                  ],
                  "ti": [
                    0,
                    166.66667175293,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.833,
                    "y": 0.833
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0.167
                  },
                  "n": "0p833_0p833_0p167_0p167",
                  "t": 17.5,
                  "s": [
                    520,
                    -320,
                    0
                  ],
                  "e": [
                    520,
                    520,
                    0
                  ],
                  "to": [
                    0,
                    -166.66667175293,
                    0
                  ],
                  "ti": [
                    0,
                    -140,
                    0
                  ]
                },
                {
                  "t": 24.1666666666667
                }
              ],
              "ix": 2
            },
            "a": {
              "a": 0,
              "k": [
                827.5,
                827.5,
                0
              ],
              "ix": 1
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6
            }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": {
                          "x": 0.5,
                          "y": 1
                        },
                        "o": {
                          "x": 0.333,
                          "y": 0
                        },
                        "n": "0p5_1_0p333_0",
                        "t": 0,
                        "s": [
                          {
                            "i": [
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "o": [
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "v": [
                              [
                                -100,
                                -100
                              ],
                              [
                                100,
                                100
                              ]
                            ],
                            "c": false
                          }
                        ],
                        "e": [
                          {
                            "i": [
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "o": [
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "v": [
                              [
                                -100,
                                -75.871
                              ],
                              [
                                100,
                                124.129
                              ]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": {
                          "x": 0.5,
                          "y": 1
                        },
                        "o": {
                          "x": 1,
                          "y": 0
                        },
                        "n": "0p5_1_1_0",
                        "t": 2.5,
                        "s": [
                          {
                            "i": [
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "o": [
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "v": [
                              [
                                -100,
                                -75.871
                              ],
                              [
                                100,
                                124.129
                              ]
                            ],
                            "c": false
                          }
                        ],
                        "e": [
                          {
                            "i": [
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "o": [
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "v": [
                              [
                                -100,
                                -260
                              ],
                              [
                                100,
                                -60
                              ]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": {
                          "x": 0.667,
                          "y": 1
                        },
                        "o": {
                          "x": 1,
                          "y": 0
                        },
                        "n": "0p667_1_1_0",
                        "t": 6.667,
                        "s": [
                          {
                            "i": [
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "o": [
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "v": [
                              [
                                -100,
                                -260
                              ],
                              [
                                100,
                                -60
                              ]
                            ],
                            "c": false
                          }
                        ],
                        "e": [
                          {
                            "i": [
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "o": [
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "v": [
                              [
                                -100,
                                -100
                              ],
                              [
                                100,
                                100
                              ]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 10
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Tracciato 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "tm",
                  "s": {
                    "a": 0,
                    "k": 0,
                    "ix": 1
                  },
                  "e": {
                    "a": 1,
                    "k": [
                      {
                        "i": {
                          "x": [
                            0.833
                          ],
                          "y": [
                            0.833
                          ]
                        },
                        "o": {
                          "x": [
                            0.167
                          ],
                          "y": [
                            0.167
                          ]
                        },
                        "n": [
                          "0p833_0p833_0p167_0p167"
                        ],
                        "t": 11.047,
                        "s": [
                          100
                        ],
                        "e": [
                          68
                        ]
                      },
                      {
                        "i": {
                          "x": [
                            0.833
                          ],
                          "y": [
                            0.833
                          ]
                        },
                        "o": {
                          "x": [
                            0.167
                          ],
                          "y": [
                            0.167
                          ]
                        },
                        "n": [
                          "0p833_0p833_0p167_0p167"
                        ],
                        "t": 11.628,
                        "s": [
                          68
                        ],
                        "e": [
                          19
                        ]
                      },
                      {
                        "i": {
                          "x": [
                            0.833
                          ],
                          "y": [
                            0.833
                          ]
                        },
                        "o": {
                          "x": [
                            0.167
                          ],
                          "y": [
                            0.167
                          ]
                        },
                        "n": [
                          "0p833_0p833_0p167_0p167"
                        ],
                        "t": 12.209,
                        "s": [
                          19
                        ],
                        "e": [
                          0
                        ]
                      },
                      {
                        "i": {
                          "x": [
                            0.833
                          ],
                          "y": [
                            0.833
                          ]
                        },
                        "o": {
                          "x": [
                            0.167
                          ],
                          "y": [
                            0.167
                          ]
                        },
                        "n": [
                          "0p833_0p833_0p167_0p167"
                        ],
                        "t": 12.403,
                        "s": [
                          0
                        ],
                        "e": [
                          0
                        ]
                      },
                      {
                        "i": {
                          "x": [
                            0.833
                          ],
                          "y": [
                            0.833
                          ]
                        },
                        "o": {
                          "x": [
                            0.167
                          ],
                          "y": [
                            0.167
                          ]
                        },
                        "n": [
                          "0p833_0p833_0p167_0p167"
                        ],
                        "t": 18.023,
                        "s": [
                          0
                        ],
                        "e": [
                          100
                        ]
                      },
                      {
                        "t": 18.6043294270833
                      }
                    ],
                    "ix": 2
                  },
                  "o": {
                    "a": 0,
                    "k": 0,
                    "ix": 3
                  },
                  "m": 1,
                  "ix": 2,
                  "nm": "Taglia tracciati 1",
                  "mn": "ADBE Vector Filter - Trim",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "nm": "Traccia 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      727.5,
                      967.5
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Trasformazione"
                }
              ],
              "nm": "Gruppo 1",
              "np": 3,
              "cix": 2,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": {
                          "x": 0.5,
                          "y": 1
                        },
                        "o": {
                          "x": 0.333,
                          "y": 0
                        },
                        "n": "0p5_1_0p333_0",
                        "t": 0,
                        "s": [
                          {
                            "i": [
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "o": [
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "v": [
                              [
                                -100,
                                100
                              ],
                              [
                                100,
                                -100
                              ]
                            ],
                            "c": false
                          }
                        ],
                        "e": [
                          {
                            "i": [
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "o": [
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "v": [
                              [
                                -100,
                                124.129
                              ],
                              [
                                100,
                                -75.871
                              ]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": {
                          "x": 0.5,
                          "y": 1
                        },
                        "o": {
                          "x": 1,
                          "y": 0
                        },
                        "n": "0p5_1_1_0",
                        "t": 2.5,
                        "s": [
                          {
                            "i": [
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "o": [
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "v": [
                              [
                                -100,
                                124.129
                              ],
                              [
                                100,
                                -75.871
                              ]
                            ],
                            "c": false
                          }
                        ],
                        "e": [
                          {
                            "i": [
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "o": [
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "v": [
                              [
                                -100,
                                -60
                              ],
                              [
                                100,
                                -260
                              ]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": {
                          "x": 0.667,
                          "y": 1
                        },
                        "o": {
                          "x": 1,
                          "y": 0
                        },
                        "n": "0p667_1_1_0",
                        "t": 6.667,
                        "s": [
                          {
                            "i": [
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "o": [
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "v": [
                              [
                                -100,
                                -60
                              ],
                              [
                                100,
                                -260
                              ]
                            ],
                            "c": false
                          }
                        ],
                        "e": [
                          {
                            "i": [
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "o": [
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "v": [
                              [
                                -100,
                                100
                              ],
                              [
                                100,
                                -100
                              ]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 10
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Tracciato 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "tm",
                  "s": {
                    "a": 1,
                    "k": [
                      {
                        "i": {
                          "x": [
                            0.833
                          ],
                          "y": [
                            0.833
                          ]
                        },
                        "o": {
                          "x": [
                            0.167
                          ],
                          "y": [
                            0.167
                          ]
                        },
                        "n": [
                          "0p833_0p833_0p167_0p167"
                        ],
                        "t": 11.047,
                        "s": [
                          0
                        ],
                        "e": [
                          31
                        ]
                      },
                      {
                        "i": {
                          "x": [
                            0.833
                          ],
                          "y": [
                            0.833
                          ]
                        },
                        "o": {
                          "x": [
                            0.167
                          ],
                          "y": [
                            0.167
                          ]
                        },
                        "n": [
                          "0p833_0p833_0p167_0p167"
                        ],
                        "t": 11.628,
                        "s": [
                          31
                        ],
                        "e": [
                          78
                        ]
                      },
                      {
                        "i": {
                          "x": [
                            0.833
                          ],
                          "y": [
                            0.833
                          ]
                        },
                        "o": {
                          "x": [
                            0.167
                          ],
                          "y": [
                            0.167
                          ]
                        },
                        "n": [
                          "0p833_0p833_0p167_0p167"
                        ],
                        "t": 12.209,
                        "s": [
                          78
                        ],
                        "e": [
                          100
                        ]
                      },
                      {
                        "i": {
                          "x": [
                            0.833
                          ],
                          "y": [
                            0.833
                          ]
                        },
                        "o": {
                          "x": [
                            0.167
                          ],
                          "y": [
                            0.167
                          ]
                        },
                        "n": [
                          "0p833_0p833_0p167_0p167"
                        ],
                        "t": 12.403,
                        "s": [
                          100
                        ],
                        "e": [
                          100
                        ]
                      },
                      {
                        "i": {
                          "x": [
                            0.833
                          ],
                          "y": [
                            0.833
                          ]
                        },
                        "o": {
                          "x": [
                            0.167
                          ],
                          "y": [
                            0.167
                          ]
                        },
                        "n": [
                          "0p833_0p833_0p167_0p167"
                        ],
                        "t": 18.023,
                        "s": [
                          100
                        ],
                        "e": [
                          0
                        ]
                      },
                      {
                        "t": 18.6043294270833
                      }
                    ],
                    "ix": 1
                  },
                  "e": {
                    "a": 0,
                    "k": 100,
                    "ix": 2
                  },
                  "o": {
                    "a": 0,
                    "k": 0,
                    "ix": 3
                  },
                  "m": 1,
                  "ix": 2,
                  "nm": "Taglia tracciati 1",
                  "mn": "ADBE Vector Filter - Trim",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "nm": "Traccia 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      927.5,
                      967.5
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Trasformazione"
                }
              ],
              "nm": "Gruppo 2",
              "np": 3,
              "cix": 2,
              "ix": 2,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 375,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          "nm": "freccia barra",
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "n": [
                    "0p833_0p833_0p167_0p167"
                  ],
                  "t": 15.833,
                  "s": [
                    100
                  ],
                  "e": [
                    0
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "n": [
                    "0p833_0p833_0p167_0p167"
                  ],
                  "t": 16.667,
                  "s": [
                    0
                  ],
                  "e": [
                    0
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "n": [
                    "0p833_0p833_0p167_0p167"
                  ],
                  "t": 17.5,
                  "s": [
                    0
                  ],
                  "e": [
                    100
                  ]
                },
                {
                  "t": 18.3333333333333
                }
              ],
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": 0.833,
                    "y": 0.833
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0.167
                  },
                  "n": "0p833_0p833_0p167_0p167",
                  "t": 10,
                  "s": [
                    520,
                    520,
                    0
                  ],
                  "e": [
                    520,
                    1520,
                    0
                  ],
                  "to": [
                    0,
                    166.66667175293,
                    0
                  ],
                  "ti": [
                    0,
                    140,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.833,
                    "y": 0.833
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0.167
                  },
                  "n": "0p833_0p833_0p167_0p167",
                  "t": 16.667,
                  "s": [
                    520,
                    1520,
                    0
                  ],
                  "e": [
                    520,
                    -320,
                    0
                  ],
                  "to": [
                    0,
                    -140,
                    0
                  ],
                  "ti": [
                    0,
                    166.66667175293,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.833,
                    "y": 0.833
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0.167
                  },
                  "n": "0p833_0p833_0p167_0p167",
                  "t": 17.5,
                  "s": [
                    520,
                    -320,
                    0
                  ],
                  "e": [
                    520,
                    520,
                    0
                  ],
                  "to": [
                    0,
                    -166.66667175293,
                    0
                  ],
                  "ti": [
                    0,
                    -140,
                    0
                  ]
                },
                {
                  "t": 24.1666666666667
                }
              ],
              "ix": 2
            },
            "a": {
              "a": 0,
              "k": [
                827.5,
                827.5,
                0
              ],
              "ix": 1
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6
            }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": {
                          "x": 0.5,
                          "y": 1
                        },
                        "o": {
                          "x": 0.333,
                          "y": 0
                        },
                        "n": "0p5_1_0p333_0",
                        "t": 0,
                        "s": [
                          {
                            "i": [
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "o": [
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "v": [
                              [
                                827.5,
                                1067.5
                              ],
                              [
                                827.5,
                                387.5
                              ]
                            ],
                            "c": false
                          }
                        ],
                        "e": [
                          {
                            "i": [
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "o": [
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "v": [
                              [
                                827.5,
                                1091.629
                              ],
                              [
                                827.5,
                                387.5
                              ]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": {
                          "x": 0.5,
                          "y": 1
                        },
                        "o": {
                          "x": 1,
                          "y": 0
                        },
                        "n": "0p5_1_1_0",
                        "t": 2.5,
                        "s": [
                          {
                            "i": [
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "o": [
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "v": [
                              [
                                827.5,
                                1091.629
                              ],
                              [
                                827.5,
                                387.5
                              ]
                            ],
                            "c": false
                          }
                        ],
                        "e": [
                          {
                            "i": [
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "o": [
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "v": [
                              [
                                827.5,
                                907.5
                              ],
                              [
                                827.5,
                                387.5
                              ]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": {
                          "x": 0.667,
                          "y": 1
                        },
                        "o": {
                          "x": 1,
                          "y": 0
                        },
                        "n": "0p667_1_1_0",
                        "t": 6.667,
                        "s": [
                          {
                            "i": [
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "o": [
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "v": [
                              [
                                827.5,
                                907.5
                              ],
                              [
                                827.5,
                                387.5
                              ]
                            ],
                            "c": false
                          }
                        ],
                        "e": [
                          {
                            "i": [
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "o": [
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "v": [
                              [
                                827.5,
                                1067.5
                              ],
                              [
                                827.5,
                                387.5
                              ]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 10
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Tracciato 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "nm": "Traccia 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Trasformazione"
                }
              ],
              "nm": "Gruppo 3",
              "np": 2,
              "cix": 2,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": {
                      "x": [
                        0.833
                      ],
                      "y": [
                        0.833
                      ]
                    },
                    "o": {
                      "x": [
                        0.167
                      ],
                      "y": [
                        0.167
                      ]
                    },
                    "n": [
                      "0p833_0p833_0p167_0p167"
                    ],
                    "t": 11.047,
                    "s": [
                      0
                    ],
                    "e": [
                      16
                    ]
                  },
                  {
                    "i": {
                      "x": [
                        0.833
                      ],
                      "y": [
                        0.833
                      ]
                    },
                    "o": {
                      "x": [
                        0.167
                      ],
                      "y": [
                        0.167
                      ]
                    },
                    "n": [
                      "0p833_0p833_0p167_0p167"
                    ],
                    "t": 11.628,
                    "s": [
                      16
                    ],
                    "e": [
                      23
                    ]
                  },
                  {
                    "i": {
                      "x": [
                        0.833
                      ],
                      "y": [
                        0.833
                      ]
                    },
                    "o": {
                      "x": [
                        0.167
                      ],
                      "y": [
                        0.167
                      ]
                    },
                    "n": [
                      "0p833_0p833_0p167_0p167"
                    ],
                    "t": 12.209,
                    "s": [
                      23
                    ],
                    "e": [
                      37
                    ]
                  },
                  {
                    "i": {
                      "x": [
                        0.833
                      ],
                      "y": [
                        0.833
                      ]
                    },
                    "o": {
                      "x": [
                        0.167
                      ],
                      "y": [
                        0.167
                      ]
                    },
                    "n": [
                      "0p833_0p833_0p167_0p167"
                    ],
                    "t": 12.791,
                    "s": [
                      37
                    ],
                    "e": [
                      50
                    ]
                  },
                  {
                    "i": {
                      "x": [
                        0.833
                      ],
                      "y": [
                        0.833
                      ]
                    },
                    "o": {
                      "x": [
                        0.167
                      ],
                      "y": [
                        0.167
                      ]
                    },
                    "n": [
                      "0p833_0p833_0p167_0p167"
                    ],
                    "t": 13.372,
                    "s": [
                      50
                    ],
                    "e": [
                      62
                    ]
                  },
                  {
                    "i": {
                      "x": [
                        0.833
                      ],
                      "y": [
                        0.833
                      ]
                    },
                    "o": {
                      "x": [
                        0.167
                      ],
                      "y": [
                        0.167
                      ]
                    },
                    "n": [
                      "0p833_0p833_0p167_0p167"
                    ],
                    "t": 13.953,
                    "s": [
                      62
                    ],
                    "e": [
                      76
                    ]
                  },
                  {
                    "i": {
                      "x": [
                        0.833
                      ],
                      "y": [
                        0.833
                      ]
                    },
                    "o": {
                      "x": [
                        0.167
                      ],
                      "y": [
                        0.167
                      ]
                    },
                    "n": [
                      "0p833_0p833_0p167_0p167"
                    ],
                    "t": 14.535,
                    "s": [
                      76
                    ],
                    "e": [
                      88
                    ]
                  },
                  {
                    "i": {
                      "x": [
                        0.833
                      ],
                      "y": [
                        0.833
                      ]
                    },
                    "o": {
                      "x": [
                        0.167
                      ],
                      "y": [
                        0.167
                      ]
                    },
                    "n": [
                      "0p833_0p833_0p167_0p167"
                    ],
                    "t": 15.116,
                    "s": [
                      88
                    ],
                    "e": [
                      100
                    ]
                  },
                  {
                    "i": {
                      "x": [
                        0.833
                      ],
                      "y": [
                        0.833
                      ]
                    },
                    "o": {
                      "x": [
                        0.167
                      ],
                      "y": [
                        0.167
                      ]
                    },
                    "n": [
                      "0p833_0p833_0p167_0p167"
                    ],
                    "t": 15.697,
                    "s": [
                      100
                    ],
                    "e": [
                      100
                    ]
                  },
                  {
                    "i": {
                      "x": [
                        0.833
                      ],
                      "y": [
                        0.833
                      ]
                    },
                    "o": {
                      "x": [
                        0.167
                      ],
                      "y": [
                        0.167
                      ]
                    },
                    "n": [
                      "0p833_0p833_0p167_0p167"
                    ],
                    "t": 18.604,
                    "s": [
                      100
                    ],
                    "e": [
                      0
                    ]
                  },
                  {
                    "t": 19.1861979166667
                  }
                ],
                "ix": 1
              },
              "e": {
                "a": 0,
                "k": 100,
                "ix": 2
              },
              "o": {
                "a": 0,
                "k": 0,
                "ix": 3
              },
              "m": 1,
              "ix": 2,
              "nm": "Taglia tracciati 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 375,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          "nm": "base contorni",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                520,
                520,
                0
              ],
              "ix": 2
            },
            "a": {
              "a": 0,
              "k": [
                827.5,
                827.5,
                0
              ],
              "ix": 1
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6
            }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          44.183,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          44.183
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          44.183
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -44.183,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          440,
                          -120
                        ],
                        [
                          440,
                          40
                        ],
                        [
                          360,
                          120
                        ],
                        [
                          -360,
                          120
                        ],
                        [
                          -440,
                          40
                        ],
                        [
                          -440,
                          -120
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Tracciato 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "nm": "Traccia 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      827.5,
                      1147.5
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Trasformazione"
                }
              ],
              "nm": "Gruppo 1",
              "np": 2,
              "cix": 2,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 375,
          "st": 0,
          "bm": 0
        }
      ]
    }
  ],
  "layers": [
    {
      "ddd": 0,
      "ind": 1,
      "ty": 0,
      "nm": "0176_download_100pt_#ffffff",
      "ln": "ffffff",
      "refId": "comp_0",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            36,
            36,
            0
          ],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [
            520,
            520,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [
            6.923,
            6.923,
            100
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "w": 1040,
      "h": 1040,
      "ip": 0,
      "op": 375,
      "st": 0,
      "bm": 0
    }
  ],
  "markers": []
}
module.exports = data;