import Component from '../../libs/component';
import { register } from '../../libs/register';



class AccordionSelect extends Component {
    constructor(container) {
        super('widget-accordion-select');

        this.container = container;
        this.type = container.dataset.type;
        this.btnAccordion =  container.querySelector(`${this._el('opener',true)} button`);
        this.accordionCollapse = container.querySelector(`${this._el('collapse',true)}`);
        this.list = [...container.getElementsByClassName(`${this._el('item')}`)];
        this.accordionState = this.accordionCollapse.dataset.collapse;
        this.localizedLabels = this.container.dataset.i18n ? JSON.parse(this.container.dataset.i18n) : { selectedLabel: 'selected' };
        this.container.removeAttribute('i18n');
        this.validated = [];

        this._setAccordion();
    }

    _setAccordion() {
         
        this.btnAccordion.addEventListener('click', (ev) => {
            ev.preventDefault();
            this._expandAccordion(ev);
        });

        this.applyBtn = this.container.querySelector(`${this._el('apply-filters',true)}`);

        this.list.forEach((item) => {
            
            let cbox = item.querySelector('input[type=checkbox]');

            cbox.addEventListener('change',({target: box}) => {

                if (box.checked) {
                    item.classList.add('widget-accordion-select__item--selected');
                    box.setAttribute('checked','');
                } else {
                    item.classList.remove('widget-accordion-select__item--selected');
                    box.removeAttribute('checked');
                }

                let selectedOptions = this.getSelectedFilters();

                this._changeAccordionLabel(selectedOptions);
                this._sendItemSelectedEvent();
            });
        });
    }

    _expandAccordion() {
        if (this.accordionState === "true") {
            this.closeAccordion();
        } else {
            this.openAccordion();
        }
    }

    // Open Accordion
    openAccordion() {
        let _height = this.accordionCollapse.querySelector('.widget-accordion-select__content').scrollHeight;

        this._sendAccordionOpeningEvent();

        this.accordionCollapse.style.height = _height + 'px';
        this.accordionCollapse.classList.add('show');
        this.accordionCollapse.setAttribute('data-collapse', 'true');
        this.btnAccordion.setAttribute('aria-expanded', 'true');

        this.accordionState = this.accordionCollapse.dataset.collapse;
    }

    // Close Accordion
    closeAccordion() {

        this.accordionCollapse.style.height = null ;
        this.accordionCollapse.classList.remove('show');
        this.accordionCollapse.setAttribute('data-collapse', 'false');
        this.btnAccordion.setAttribute('aria-expanded', 'false');
        this.accordionState = this.accordionCollapse.dataset.collapse;
    }

    _selectActive(len) {

        if (len > 0)
            this.container.classList.add('widget-accordion-select--active');
        else
            this.container.classList.remove('widget-accordion-select--active');
    }

    _changeAccordionLabel(selected) {

        let accordionOpener = this.container.querySelector(`${this._el('opener',true)}`);
        let accordionSelectedLabel = accordionOpener.querySelector('.selected');
        let placeholder = accordionOpener.querySelector('.label').textContent;
        let newLabel = '';

        if (selected.length === 1) {
            let selectedItem = Array.from(this.list).filter(item => item.querySelector(`input[value="${selected[0]}"]`) ? true : false)[0];
            newLabel = selectedItem.querySelector('label').textContent;
        } else if (selected.length > 1) {
            newLabel = `${selected.length} ${placeholder} ${this.localizedLabels.selectedLabel}`;
        }

        accordionSelectedLabel.textContent = newLabel;
    }

    applyValidatedFilters() {
        this.validated = this.getSelectedFilters();
        return this.validated;
    }

    getSelectedFilters() {

        return Array(...this.list).reduce((acc, item) => {
            let cbox = item.querySelector('input[type=checkbox]');
            if (cbox.checked === true) {
                acc.push(cbox.value);
            }
            return acc;
        }, []);
    }

    getValidatedFilters() {
        return this.validated;
    }

    getValidatedFiltersCount() {
        return this.validated.length;
    }

    setValidatedFilters(data) {

        this.resetAccordion();

        data.forEach((val) => {
            console.log("setting accordion filter",val)
            let item = Array.from(this.list).filter(item => item.querySelector(`input[value="${val}"]`) ? true : false)[0];
            if (item)
                item.querySelector('input').click();
        });

        this.applyValidatedFilters();
    }

    _sendAccordionOpeningEvent() {
        let event = new CustomEvent('accordionOpening', { bubbles: true });
        this.container.dispatchEvent(event);
    }

    _sendItemSelectedEvent() {
        let event = new CustomEvent('accordionItemSelected', { bubbles: true });
        this.container.dispatchEvent(event);
    }

    resetValidatedValues() {

        this.resetAccordion(false);

        if (this.validated.length > 0) {

            this.validated.forEach((val) => {
                let cbox = this.container.querySelector(`.widget-accordion-select__item input[value="${val}"]`);
                if (cbox) {
                    cbox.checked = true;
                    cbox.setAttribute('checked','');
                    cbox.parentElement.classList.add('widget-accordion-select__item--selected');
                }
            });

            this._changeAccordionLabel(this.validated);
            //this._sendItemSelectedEvent();
        }
    }

    resetAccordion(hardReset=true) {

        this._changeAccordionLabel(0);

        this.list.forEach((item) => {
            let cbox = item.querySelector('input[type=checkbox]');
            if (cbox) {
                cbox.checked = false;
                cbox.removeAttribute('checked');
                item.classList.remove('widget-accordion-select__item--selected');
            }
        });

        if (hardReset) {
            this.validated = [];
        }
    }
}

register.registerClass('.widget-accordion-select', AccordionSelect);