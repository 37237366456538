import Component from '../../libs/component';
import { register } from '../../libs/register';
import { analytics } from '../../libs/analytics';



class Chatbot extends Component {
    constructor(container) {
        super('widget-chatbot');

        this.container = container;
        this.chatbot = this.container.querySelector('.help-container');
        this.btnOpen = this.container.querySelector('.help-label');
        this.chatbotMinimized = this.container.querySelector('.help-minimized');
        this.chatbotInner = this.chatbotMinimized.querySelectorAll('.help-inner');
        this.chatSelectContainer = this.container.querySelector('.help-select-container');
        this.chatSelect = container.querySelector('.help-select');

        this.btnClose = this.chatbotMinimized.querySelector('.help-close');
        this.chatList = this.container.querySelector('.help-list-items');
        this.btnOpenChat = this.container.querySelector('.help-open-chat')

        this.chatbotOpened = false;
        this.chatbotDefaultSize = 60;

        this.chatSelectConfig = {
            panelItem: {
                position: 'bottom',
                item: '<span></span>'
            }
        };

        this._addChatbot();

        this._init();

        this._initSelect();
    }

    _addChatbot() {
        if (window.chatUtilities && window.chatUtilities.chatVisible) {

            let retryCount = 5;
            const dataLib = this.chatbot.dataset.lib || '/etc.clientlibs/nexinew/clientlibs/clientlib-chatbot/resources/chat.conf.js';

            const cxbusSetup = () => {

                if (CXBus) {

                    CXBus.configure({
                        debug: false,
                        pluginsPath: 'https://apps.mypurecloud.com/widgets/9.0.017.06/plugins/'
                    });

                    CXBus
                        .loadFile(dataLib)
                        .done(() => {
                            console.log('CXBus Chat plugin loading');
                            CXBus.loadPlugin('widgets-core');
                        })
                        .fail(() => {
                            throw Error('Loading "chat.conf.js" failed');
                        });
                } else {
                    throw Error('No CXBus loaded yet.');
                }
            };

            const onCXloaded = () => {
                try {
                    retryCount--;
                    cxbusSetup()
                } catch (err) {
                    console.log('CXBus loading error:', err);

                    if (retryCount > 0) {
                        setTimeout(onCXloaded, 2000);
                    } else {
                        console.log('CXBus loading aborted!');
                    }
                }
            }

            const chat = document.createElement('script');
            chat.src = 'https://apps.mypurecloud.ie/widgets/cxbus.min.js';
            chat.id = 'hljs';
            chat.async = true;
            chat.onload = onCXloaded;

            document.body.appendChild(chat);
        }
    }

    async _init() {
        this.anime = await import('animejs/lib/anime.es.js');

        if (this.chatbot) {
            this.btnOpen.addEventListener('click', (ev) => {
                ev.stopPropagation();
                this._openClickHandler();
                this.sendAnalytics('click', 'Bubble assistenza');
            });

            this.btnClose.addEventListener('click', (ev) => {
                ev.stopPropagation();
                this._closeClickHandler();
            });

            this._addClickOutside();
        }

    }

    // Initialize select
    async _initSelect() {
        const Select = await import('select-custom');

        if (!this.selectCustom) {
            const config = this.chatSelectConfig;

            this.selectCustom = new Select.default(this.chatSelect, config);

            this.selectCustom.onOpen = () => {

                if (this.scrollbar)
                    this.scrollbar.update();
            };

            this.selectCustom.init();

            this._addCustomScrollbar();

            this.chatSelectContainer.classList.add('active');

        }

        this._fetchHelpLinks();

        // When chat assistance is opening, close help tool
        this.btnOpenChat.addEventListener('click', () => {
            this._closeClickHandler();
        });
    }

    // Close select
    _closeSelect() {
        if (this.chatSelectContainer.querySelector('.custom-select--open'))
            this.chatSelectContainer.querySelector('.custom-select__opener').click();
    }

    async _addCustomScrollbar() {
        const scrollbar = this.container.querySelector('.custom-select__options');

        if (scrollbar) {
            const PerfectScrollbar = await import('perfect-scrollbar');
            this.scrollbar = new PerfectScrollbar.default(scrollbar, {
                //scrollYMarginOffset: '11',
                suppressScrollX: true,
                wheelPropagation: false,
                swipeEasing: true
            });

            this.scrollbar.update();

            const scrollbarRail = scrollbar.querySelector('.ps__rail-y');
            scrollbarRail.addEventListener('click', (ev) => {
                ev.stopImmediatePropagation();  // Disable select closing on scrollbar click
            });
        }
    }

    _detachClickHandler() {
        this.btnClose.removeEventListener('click', this._openClickHandler);
    }

    _addClickOutside() {

        let handler = (ev) => {
            if (!this.container.contains(ev.target)) {
                this._closeClickHandler(ev);
            }
        };

        document.body.addEventListener('click', handler);
    }


    // Open Chatbot
    _openClickHandler() {
        if (!this.chatbotOpened) {
            console.log('***HELP WIDGET OPENED');

            let beforeWidth = this.chatbotMinimized.offsetWidth;
            let beforeHeight = this.chatbotMinimized.offsetHeight;

            this.chatbotMinimized.classList.add('is-opening');

            let width = this.chatbotMinimized.querySelector('.help-content').offsetWidth;
            let height = this.chatbotMinimized.querySelector('.help-content').offsetHeight;

            this.chatbotMinimized.classList.remove('is-opening');

            let chatbot = this;

            this.anime.default({
                targets: this.chatbotMinimized,
                width: [beforeWidth, width],
                height: [beforeHeight, height],
                duration: 400,
                easing: 'easeOutExpo',

                begin: function () {
                    chatbot.chatbotMinimized.classList.add('help-expanded');
                },
                complete: function () {

                    chatbot.chatbotMinimized.style.width = "";
                    chatbot.chatbotMinimized.style.height = "";

                    chatbot.chatbotInner.forEach(element => {
                        element.classList.add('active');
                    });

                    chatbot.sendAnalytics('impression', 'Bubble assistenza aperto');
                }
            });

            this.chatbotOpened = true;
        }
    }


    // Close Chatbot
    _closeClickHandler() {
        if (this.chatbotOpened) {
            console.log('***HELP WIDGET CLOSED');

            let beforeWidth = this.chatbotMinimized.offsetWidth;
            let beforeHeight = this.chatbotMinimized.offsetHeight;

            let width = this.chatbotDefaultSize;
            let height = this.chatbotDefaultSize;

            if (this.chatSelectContainer.querySelector('.custom-select--open')) {
                this._closeSelect();
            }

            let chatbot = this;

            this.anime.default({
                targets: this.chatbotMinimized,
                easing: 'easeOutExpo',
                duration: 400,
                width: [beforeWidth, width],
                height: [beforeHeight, height],
                begin: function () {
                    chatbot.chatbotMinimized.classList.remove('help-expanded');
                },
                complete: function () {
                    chatbot.chatbotMinimized.style.width = "";
                    chatbot.chatbotMinimized.style.height = "";
                }
            });

            this.chatbotInner.forEach(element => {
                element.classList.remove('active');
            });

            this.chatbotOpened = false;
        }
    }

    // Insert related help links 
    _changeItemList(data, ev) {
        let path = ev.target.value;
        let itemsList = data.find((elem) => elem.path === path);

        this._insertHelpLinks(itemsList);
    }

    // Inserts help links
    _insertHelpLinks(elem) {
        console.debug(elem);

        this.chatList.innerHTML = "";

        elem.sublevel.forEach((item) => {

            let helpItem = document.createElement('li');
            this.chatList.appendChild(helpItem);

            let helpItemLink = document.createElement('a');
            helpItemLink.href = item.url;
            helpItemLink.appendChild(document.createTextNode(item.title));

            helpItem.appendChild(helpItemLink);

        });
    }

    _fetchHelpLinks() {

        let request = new XMLHttpRequest();
        let data;
        let dataUrl = this.chatbot.dataset.url;

        let helpItemsList = [];
        let currentPage = this.chatbot.getAttribute('data-current-page');

        // console.debug(currentPage);

        let resPath = this.endpoint ? this.endpoint : dataUrl; //'/assets/files/assist.json'; // window.resources.assistance

        request.open('GET', resPath, true);

        request.onload = () => {

            if (request.status === 200) {
                data = request.responseText;
                helpItemsList = JSON.parse(data);

                // Find if there is the same path inside data attribute
                const currentTree = helpItemsList.find((elem) => currentPage.indexOf(elem.path) >= 0);
                console.debug(currentTree, helpItemsList);

                if (currentTree) {

                    this.chatSelectContainer.remove();
                    this._insertHelpLinks(currentTree);

                } else {

                    this.chatSelect.addEventListener('change', (ev) => {
                        this._changeItemList(helpItemsList, ev);
                    });

                    let defaultPath = this.chatSelectContainer.querySelector('.custom-select__option--selected').dataset.value;

                    if (defaultPath) {
                        let defaultItemsList = helpItemsList.find((elem) => elem.path === defaultPath);
                        this._insertHelpLinks(defaultItemsList);
                    }
                }

            } else {
                console.log("We reached our target server, but it returned an error");
            }
        }

        request.onerror = () => {
            console.warn('*** ERROR: Help Assistant data not retrieved:', request);
        };

        request.send();

    }

    sendAnalytics(action, label) {
        analytics.sendData({
            'event': 'gaEvent_help',
            'eventCategory': 'Help',
            'eventAction': action,
            'eventLabel': label
        });
    }

}

register.registerClass('.widget-chatbot', Chatbot);