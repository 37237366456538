import Component from '../../libs/component';
import { register } from '../../libs/register';
import {
    shareEmail,
    shareTwitter,
    shareFacebook,
    shareLinkedin,
    shareXing,
    copyToClipboard
} from '../../libs/commons';



class ShareButtons extends Component {

    constructor(container) {
        super('widget-share');
        
        this.container = container.querySelector(`${this._el('container',true)}`);
        this.buttonsList = this.container.querySelectorAll(`${this._el('buttons',true)} ${this._el('share-btn',true)}`);

        const title = document.querySelector('.widget-hero__title');
        
        this.shareTitle = title ? title.textContent.trim() : document.title,
        this.shareUrl = window.location.href;

        this.buttonsList.forEach((btn) => {
            btn.addEventListener('click',(ev) => this._handleShareButton(ev));
        });
    }

    _handleShareButton({target: el}) {

        let shareData = {
            title: encodeURI(this.shareTitle),
            url: encodeURI(this.shareUrl)
        }

        switch(el.dataset.type) {
            case 'email':
                shareEmail(shareData);
                break;
            case 'twitter':
                shareTwitter(shareData);
                break;
            case 'linkedin':
                shareLinkedin(shareData);
                break;
            case 'facebook':
                shareFacebook(shareData);
                break;
            case 'xing':
                shareXing(shareData);
                break;
            case 'copy':
                if (copyToClipboard(shareData)) {
                    //show success message
                } else {
                    //show error message
                }
                break;
        }
    }
}

register.registerClass('.widget-share', ShareButtons);