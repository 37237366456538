import Component from '../../libs/component';
import { register } from '../../libs/register';
import { analytics } from '../../libs/analytics';
import { getPrevSibling } from '../../libs/utils';


class Accordion extends Component {

    constructor(container) {
        super('elem-accordion');

        this.accordion = container;
        this.accordion.addEventListener('click', ({target}) => {
            if (target.tagName != 'LABEL') return;
            const checkbox = getPrevSibling(target, 'input[type=checkbox]:not(:checked)');
            if (checkbox)
                this.sendOpenAnalytics(target);
        });

        if (this.accordion.classList.contains(('selective-mode')))
            this._initSelectiveMode();
    }

    _initSelectiveMode() {

        this.accordion.addEventListener('click', ({target:label}) => {
            if (label.tagName != 'LABEL') return;

            const activeCheckboxes = this.accordion.querySelectorAll('input[type=checkbox]:checked');

            activeCheckboxes.forEach((cb) => {
                setTimeout(() => { cb.checked = false },0);
            });
        });
    }

    sendOpenAnalytics(acc) {

        analytics.sendData({
            event: 'accordion',
            accordion: {
                action: 'open',
                title: acc.textContent.trim()
            }
        });
    }
}


register.registerClass('.elem-accordion', Accordion);