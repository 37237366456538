import Component from '../../libs/component';
import { register } from '../../libs/register';
import {
    shareEmail,
    shareTwitter,
    shareLinkedin,
    shareFacebook,
    shareXing,
    copyToClipboard
} from '../../libs/commons';



class Card extends Component {

    constructor(container) {
        super('elem-card');
        
        this.container = container.querySelector(`${this._el('container',true)}`);
        this.shareBtn = container.querySelector(`${this._el('open-share-panel',true)}`);
        this.ctaCalendar = container.querySelector(`${this._el('cta',true)}.btn-calendar`);

        if (this.shareBtn) {

            let title = this.container.querySelector(`${this._el('title',true)}`) ||
                        this.container.querySelector(`${this._el('name',true)}`),
                text = this.container.querySelector(`${this._el('description',true)}`),
                url = this.container.querySelector(`a${this._el('overlay',true)}`);
            
            this.shareBusy = false;
            this.xingEnabled = Boolean(container.dataset.xingEnabled !== undefined);
            this.cardTitle = title ? title.textContent.trim() : '',
            this.cardText = text ? text.textContent.trim() : '',
            this.cardUrl = url ? url.href : this.shareBtn.dataset.url ? window.location.origin + this.shareBtn.dataset.url : '';
            this.content = container.querySelector(`${this._el('content',true)}`);

            this.shareBtn.addEventListener('click', (ev) => this._openCloseSharePanel(ev));
        }

        if (this.ctaCalendar) { // Disabling calendar cta after click
            this.ctaCalendar.addEventListener('click', (ev) => this._disableCta(ev));
        }
    }

    _openCloseSharePanel(ev) {

        if (!ev.target.classList.contains('active')) {

            if (navigator.share && !window.deviceBreakpoints.bpDesktop.matches) {
                this._shareContent({
                    title: this.cardTitle,
                    text: this.cardText,
                    url: this.cardUrl
                });
            } else
                this._openSharePanel();
                
        } else {
            this._closeSharePanel();
        }
    }

    _openSharePanel() {
        this.content.classList.add('share-opened');
        this.shareBtn.classList.add('active');
        this._addClickOutside();
        this._addShareButtons()
    }

    _closeSharePanel() {
        this.content.classList.remove('share-opened');
        this.shareBtn.classList.remove('active');
        this._removeShareButtons();
    }

    _addShareButtons() {

        let shareContainer = this.container.querySelector(`${this._el('share-container',true)}`),
            overlay = this.container.querySelector(`${this._el('overlay',true)}`),
            btnList = [
                {
                    class: `${this._el('email-share-btn')}`,
                    icon: 'email'
                },
                {
                    class: `${this._el('copy-share-btn')}`,
                    icon: 'copy'
                },
                {
                    class: `${this._el('twitter-share-btn')}`,
                    icon: 'twitter'
                },
                {
                    class: `${this._el('linkedin-share-btn')}`,
                    icon: 'linkedin'
                },
                {
                    class: `${this._el('facebook-share-btn')}`,
                    icon: 'facebook'
                },
            ];

        if (this.xingEnabled) {
            btnList.push({
                class: `${this._el('xing-share-btn')}`,
                icon: 'xing'
            });
        }

        if (!shareContainer) {
            shareContainer = document.createElement("DIV");
            shareContainer.className = (`${this._el('share-container')}`);
            shareContainer.addEventListener('click', (ev) => this._handleShareButton(ev));
            this.container.insertBefore(shareContainer,overlay);
        }

        btnList.forEach((el) => {
            let btn = document.createElement("BUTTON");
            let icon = document.createElement("EM");
            btn.className = `${this._el('share-btn')} ${el.class}`;
            btn.dataset.type = el.icon;
            icon.className = 'icon-' + el.icon;
            btn.appendChild(icon);
            shareContainer.appendChild(btn);
        });
    }

    _handleShareButton({target: el}) {

        let shareData = {
            title: encodeURI(this.cardTitle),
            text: encodeURI(this.cardText),
            url: encodeURI(this.cardUrl)
        }

        switch(el.dataset.type) {
            case 'email':
                shareEmail(shareData);
                break;
            case 'twitter':
                shareTwitter(shareData);
                break;
            case 'linkedin':
                shareLinkedin(shareData);
                break;
            case 'facebook':
                shareFacebook(shareData);
                break;
            case 'xing':
                shareXing(shareData);
                break;
            case 'copy':
                if (copyToClipboard(shareData)) {
                    //show success message
                } else {
                    //show error message
                }
                break;
        }
    }

    _shareContent(data) {

        if (!this.shareBusy) {
        
            this.shareBusy = true;

            try {
                navigator.share({
                    title: encodeURI(data.title),
                    text: encodeURI(data.text),
                    url: encodeURI(data.url)
                }).then(() => {
                    console.log('Thanks for sharing!');
                    this.shareBusy = false;
                })
                .catch(() => {
                    console.warn('Card share FAILED');
                    this.shareBusy = false;
                });

            } catch(err) {
                console.error('Card share FAILED',err);
                this.shareBusy = false;
            }
        }
    }

    _removeShareButtons() {

        let shareContainer = this.container.querySelector(`${this._el('share-container',true)}`);

        if (shareContainer) {

            while (shareContainer.firstChild) {
                shareContainer.removeChild(shareContainer.firstChild);
            }

            this.container.removeChild(shareContainer);
        }
    }

    _addClickOutside() {

        let handler = ({target}) => {
            if (!this.container.contains(target)) {
                this._closeSharePanel();
                document.body.removeEventListener('click', handler);
            }
        };

        document.body.addEventListener('click', handler);
    }

    _disableCta(ev) {

        ev.preventDefault();

        if (!this.ctaTimer) {

            this.ctaLink = this.ctaLink || ev.target.getAttribute('href');
            ev.target.href = '#';
            ev.target.setAttribute('disabled','true');
            ev.target.setAttribute('aria-disabled','true');

            this.ctaTimer = setTimeout(() => {
                    ev.target.href = this.ctaLink;
                    ev.target.removeAttribute('disabled');
                    ev.target.removeAttribute('aria-disabled');
                    this.ctaTimer = null;
                }, 6000);

            window.open(this.ctaLink,'_blank');
        }
    }
}

register.registerClass('.elem-card', Card);